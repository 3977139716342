export const environment = {
  name: 'prod',
  production: true,
  protocol: 'https://',
  baseAddress: 'api.monek.com',
  odinBaseAddress: 'api.monek.com/odin',
  clientRoot: 'https://portal.monek.com/',
  apiRoot: 'api.monek.com/odin/',
  releaseVersion: 'v1.15.0',

  // cognito
  userPoolId: 'eu-west-2_sXBBTJKkp',
  appClientId: '2p60iqblj21910inec6qti9u5i',
  hostedLoginUrl: 'https://authorise.monek.com',
  authCallbackUrl: 'https://portal.monek.com/authentication/auth-callback',

  congitoResource: 'https://portal.monek.com/dashboard',
  cognitoAudience: 'https://api.monek.com/odin/transactions',
  sessionInactivityLimit: 900000,

  sentryKey:
    'https://ba13795508924cf397d50d339adb7cda@o4505487026159616.ingest.sentry.io/4505487088812032',
  sentrySampleRate: 0.5,
  sentryMaskData: true,

  //xero
  xeroProtocol: 'https://',
  xeroBaseAddress: 'localhost:44383',

  featureToggles: {
    fuzzySearch: true,
    'txn-recharge': false,
    'vt-page': true,
    'pebl-page': true,
    'pci-widget': false,
    'dashboard-metrics': true,
    'user-mfa-management': true,
    'pebl-qr': true,
    'extended-transactions': true,
    'integrations': false
  },

  brandings: [
    {
      name: 'MONEK BRANDING',
      logoUrl: '/assets/images/logo.png',
      brandName: 'MONEK',
      hasTextLogo: true,
      textLogoClass: 'monek-font',
      fixedTheme: false, // can change theming?
      themeClass: '', // theme class if fixedTheme is true
      logoWidth: 'auto',
      logoHeight: '20px',
      availableThemes: [
        { value: 'light', view: 'Light Mode' },
        { value: 'dark', view: 'Dark Mode' },
      ],
    },
  ],
  selectedBrandName: 'MONEK BRANDING',
};
