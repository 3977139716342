import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ITransactionSettled, ScrollDirection } from '../../models';

@Component({
  standalone: false,
  selector: 'odin-settlement-daily-list',
  templateUrl: './settlement-daily-list.component.html',
  styleUrls: ['./settlement-daily-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementDailyListComponent {
  public rowSize = 62;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport | null = null;

  @Input() transactions: ITransactionSettled[] = [];
  @Input() set loading(value: boolean | null) {
    this.scrollDisable = value || false;
  }
  @Input() allowSelection: boolean | null = true;
  @Input() hasNext: boolean | null = false;
  @Input() hasPrev: boolean | null = false;
  @Output() scrollDirection: EventEmitter<ScrollDirection> =
    new EventEmitter<ScrollDirection>();

  scrollDisable = false;
}
