<div class="p-3 page-height overflow-auto">
    <mat-card>
        <mat-card-content>
          <h2>Xero</h2>
          <div class="mt-3 mb-0 hr-light"></div>
          <div class="d-flex justify-content-between align-items-center p-3">
            <div class="d-flex flex-column align-items-start me-1 p-3 w-100">
              <p>
                Enhance your accounting workflow by integrating Monek services into your Xero account
              </p>
              <ul>
                <li>Click <strong>"Connect to Xero"</strong> to link your Xero account.</li>
                <li>Once connected, you can add more Xero organizations by clicking the manage button.</li>
                <li>Some integrations can only be modified or removed through the Xero website.</li>
              </ul>
            </div>
            <div class="d-flex ms-auto w-100 justify-content-end align-items-center">
              <img *ngIf="!xeroManageEnabled()" class="img-button me-1" (click)="connectXero()" src="/assets/images/xero-connect-blue.svg"/>
              <button mat-flat-button class="me-1 mon-border-primary border mon-text-primary mon-bg-primary" [disabled]="!xeroManageEnabled()" (click)="manageXero()"><mat-icon>settings</mat-icon> Manage</button>
            </div>
          </div>
        </mat-card-content>
    </mat-card>
</div>