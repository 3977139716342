import { Component, OnInit, signal, WritableSignal } from "@angular/core";
import { Binding, BindingSetupBody, PaymentServiceStatus, Tenant } from "../../models/xero";
import { ActivatedRoute, Router } from "@angular/router";
import { XeroIntegrationsService } from "../../services/xero-integrations-service.service";
import { ConfirmationService, MerchantChangeService } from "@odin/odin-core";
import { TenantBinding } from "../../models/xero/tenant-binding";
import { catchError, EMPTY, Observable, throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  standalone: false,
  selector: 'odin-integrations-xero-page',
  templateUrl: './integrations-xero-page.component.html',
  styleUrl: './integrations-xero-page.component.css',
})
export class IntegrationsXeroPageComponent implements OnInit{
  tenantBindings: WritableSignal<TenantBinding[]> = signal([]);
  loading: WritableSignal<boolean> = signal(true);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly xeroIntegrationsService: XeroIntegrationsService,
    private readonly merchantChangeService: MerchantChangeService,
    private readonly confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.merchantChangeService.merchantChangeEvent.subscribe(() => {
      this.router.navigate(['/integrations']);
    });

    this.updateBindings();
  }

  back(): void {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

  allowPaymentServiceConfigure(binding: TenantBinding): boolean {
    return binding.paymentServiceStatus !== PaymentServiceStatus.Active;
  }

  getPaymentServiceStatus(binding: TenantBinding): string {
    return PaymentServiceStatus[binding.paymentServiceStatus];
  }

  configurePaymentService(tenantId: string): void {
    this.router.navigate([tenantId], {relativeTo: this.route});
  }

  connectXero(): void {
    this.xeroIntegrationsService.getLoginUrl()
      .subscribe((url: string) => {
        window.location.href = url;
    });
  }

  deleteBinding(tenantId: string): void {
    this.confirmationService.ConfirmDecline("Unlink your Monek Business from this Xero Organisation?",
      "Any configured Monek Payment Services will be left inoperable for this business. Any unsettled invoices using Monek Payment Services will not be payable. Payment Services may be deleted from the Xero website.",
      "Unlink", "Cancel", "warn", "primary"
      ).subscribe(result => {
        if (result) {
          this.xeroIntegrationsService.deleteBinding(tenantId)
            .pipe(catchError(error => this.handleUnauthorized(error)))
            .subscribe(() => {
              this.updateBindings();
            });
        }
      });
  }

  deleteAllBindings(): void {
    this.confirmationService.ConfirmDecline("Revoke all Monek/Xero integrations?", 
      "Any configured Monek Payment Services will be left inoperable. Any unsettled invoices using Monek Payment Services will not be payable. Payment Services may be deleted from the Xero website.", 
      "Revoke Access", "Cancel", "warn", "primary")
      .subscribe(result => {
        if (result) {
          this.xeroIntegrationsService.deleteAllBindings()
            .subscribe();
        }
      });
  }

  private findBinding(bindings: Binding[], tenantId: string) {
    return bindings.find(b => b.tenantId.trim().toLowerCase() === tenantId.trim().toLowerCase());
  }

  private createBinding(tenantId: string, accessToken: string, refreshToken: string): void {
    const body: BindingSetupBody = {
      buid: '',
      tenantId: tenantId,
      accessToken: accessToken,
      refreshToken: refreshToken
    };

    this.xeroIntegrationsService.createBinding(body).subscribe();
  }

  private updateBindings() {
    this.loading.set(true);

    //Retrieve existing bindings
    this.xeroIntegrationsService.getBindings().subscribe(bindings => {
      this.route.queryParams.subscribe((params) => {
        //Retrieve the code from the query parameters if it exists
        const code = params["code"];
        
        //Get the tenant connections for this oauth code, or use existing token if no code is provided
        this.xeroIntegrationsService.getConnections(code)
          .pipe(catchError(error => this.handleUnauthorized(error)))
          .subscribe(response => {
            //Map the tenants against the bindings to create bound tenants array
            const boundTenants = response.tenants.flatMap(t => {
              //If this tenant exists in the bindings, it is already bound
              const binding = this.findBinding(bindings, t.tenantId);

              //If not, bind the tenant
              if (binding === undefined) {
                this.createBinding(t.tenantId, response.accessToken, response.refreshToken);
              }

              //Return a bound tenant for display in the table
              return {
                tenantId: t.tenantId,
                tenantName: t.tenantName,
                paymentServiceStatus: binding?.paymentServiceStatus ?? PaymentServiceStatus.Inactive,
              } as TenantBinding;
            });

            //Update the table with our bound tenants
            this.tenantBindings.set(boundTenants);

            this.loading.set(false);
          });
      });
    });    
  }

  private handleUnauthorized(error: HttpErrorResponse): Observable<never> {
    if (error.status == 401) {
      this.router.navigate(["/integrations"]);
      return EMPTY;
    }

    return throwError(() => error);
  }
}