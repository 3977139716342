import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { XeroIntegrationsService } from '../../services/xero-integrations-service.service';
import { MerchantChangeService } from '@odin/odin-core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  standalone: false,
  selector: 'odin-integrations-page',
  templateUrl: './integrations-page.component.html',
  styleUrl: './integrations-page.component.css',
})
export class IntegrationsPageComponent implements OnInit {
  xeroManageEnabled: WritableSignal<boolean> = signal<boolean>(false);
  xeroRevokeEnabled: WritableSignal<boolean> = signal<boolean>(true);

  constructor(
    private readonly xeroIntegrationsService: XeroIntegrationsService,
    private readonly merchantChangeService: MerchantChangeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.merchantChangeService.merchantChangeEvent.subscribe(() => {
      this.router.navigate(['/integrations']);
    });

    this.loadButtonState();
  }
  
  connectXero(): void {
    this.xeroIntegrationsService.getLoginUrl()
      .subscribe((url: string) => {
        window.location.href = url;
    });
  }

  manageXero(): void {
    this.router.navigate(["xero"], {relativeTo: this.route});
  }

  private loadButtonState() {
    this.xeroIntegrationsService.getBindings()
      .subscribe(bindings => {
        this.xeroManageEnabled.set(bindings.length > 0);
        this.xeroRevokeEnabled.set(bindings.length === 0);
      });
  }
}
