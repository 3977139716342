import { ElementRef, InjectionToken, Provider } from '@angular/core';
import { LOADED } from './loaded.token';
import { merge, Observable, fromEvent } from 'rxjs';
import {
  endWith,
  filter,
  map,
  mapTo,
  scan,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

export const MICRO_OFFSET = 10 ** -6;
export const PULLED_DISTANCE = 240;

export const PULLING = new InjectionToken<Observable<number>>(
  'Stream that emits content pulling',
);

export const PULL_TO_REFRESH_PROVIDERS: Provider[] = [
  {
    provide: PULLING,
    deps: [LOADED, ElementRef],
    useFactory: pullingFactory,
  },
];

export function pullingFactory(
  loaded$: Observable<unknown>,
  { nativeElement }: ElementRef<HTMLElement>,
): Observable<number> {
  return merge(
    fromEvent<TouchEvent>(nativeElement, 'touchstart').pipe(
      filter(() => nativeElement.scrollTop === 0),
      switchMap((touchStart: TouchEvent) =>
        fromEvent(nativeElement, 'touchmove').pipe(
          map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (touchMove: any) =>
              touchMove.touches[0].clientY - touchStart.touches[0].clientY,
          ),
          takeUntil(fromEvent(nativeElement, 'touchend')),
          endWith(0),
        ),
      ),
    ),
    loaded$.pipe(mapTo(NaN)),
  ).pipe(
    scan((max, current) => {
      if (isNaN(current)) {
        return 0;
      }

      const dropped = current === 0 && max > PULLED_DISTANCE;

      return dropped ? PULLED_DISTANCE : current + MICRO_OFFSET;
    }, 0),
  );
}
