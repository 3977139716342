import { Pipe, PipeTransform } from "@angular/core";

class TimeDivision {
    constructor(public amount: number, public name: Intl.RelativeTimeFormatUnit) { }
}

const TIMEDIVISIONS = [
    new TimeDivision(60, 'seconds'),
    new TimeDivision(60, 'minutes'),
    new TimeDivision(24, 'hours'),
    new TimeDivision(7, 'days'),
    new TimeDivision(4.34524, 'weeks'),
    new TimeDivision(12, 'months'),
    new TimeDivision(Infinity, 'years'),
]

@Pipe({ name: 'relativeTime', pure: true, standalone: false })
export class RelativeTimePipe implements PipeTransform {
    transform(eventDate: Date): string {
        const comparisonDate: Date = new Date();
        let duration: number = (new Date(eventDate).getTime() - new Date(comparisonDate).getTime()) / 1000;

        for (let i = 0; i < TIMEDIVISIONS.length; i++) {
            const division = TIMEDIVISIONS[i];
            if (Math.abs(Math.round(duration)) < division.amount) {
                return new Intl.RelativeTimeFormat("en", {
                    localeMatcher: 'best fit',
                    numeric: 'auto',
                    style: 'long'
                }).format(Math.round(duration), division.name);
            }
            duration /= division.amount;
        }

        return '';
    }
}
