<odin-print-receipt *ngIf="transaction() !== undefined" [receiptDetail]="createReceipt()"></odin-print-receipt>

<div class="p-2 page-height overflow-auto">
  <div *ngIf="transaction !== undefined && !loading; else loadingSpinner"
    class="d-flex justify-content-between flex-column" style="max-height: 100%">
    <div class="scrollable-container overflow-auto flex-space">
      <div class="d-flex justify-content-between align-items-center main-panel rounded-3 p-3">
        <div class="d-flex align-items-center justify-content-start gap-2 w-100 flex-wrap">
          <button class="bg-transparent border-0 d-flex align-items-center justify-content-center"
            (click)="BackButtonHandle()">
            <mat-icon class="cursor-pointer my-0 text-dark">chevron_left</mat-icon>
          </button>
          <h2 class="card-title m-0 flex-shrink-0">
            {{ transactionType }} Detail
          </h2>

          <div class="flex-fill"></div>

          <div class="d-flex align-items-center justify-content-between justify-content-md-end gap-2 flex-fill">
            <button mat-flat-button *ngIf="canPrint()" (click)="printReceipt()" data-test="Receipt"
              class="d-block bg-white-always mon-border-primary border mon-text-primary flex-shrink-0">
              Receipt
            </button>

            <button mat-flat-button *ngIf="canReverse()" [disabled]="transaction() === null" mat-flat-button
              (click)="startReverseRequest()" data-test="Reverse"
              class="d-block bg-transparent mon-border-danger border mon-text-danger flex-shrink-0">
              Reverse
            </button>

            <button mat-flat-button *ngIf="canRefund()" [disabled]="
          transaction() === null ||
          transaction().remainingValue === 0
        " (click)="startRefundRequest()" data-test="Refund"
              class="d-block bg-transparent mon-border-danger border mon-text-danger flex-shrink-0">
              Refund
            </button>

            <button mat-flat-button *ngIf="canReCharge()" [disabled]="transaction() === null" mat-flat-button
              (click)="startRechargeRequest()" data-test="Recharge"
              class="d-block bg-transparent mon-border-warning border mon-text-warning flex-shrink-0">
              Recharge
            </button>
          </div>
        </div>
      </div>

      <div class="w-100 main-panel rounded-3 mt-3 p-3 overflow-hidden">
        <div class="d-block d-sm-flex align-items-center justify-content-start gap-5">
          <div class="d-flex align-items-center justify-content-start gap-1 flex-shrink-0 flex-grow-1">
            <div class="me-2 d-flex align-items-center">
              <div style="max-width: 50px"
                class="w-100 border border-2 border-white rounded-3 overflow-hidden shadow-sm p-1">
                <fa-icon class="transaction-icon d-block mx-auto w-100" [matTooltip]="
                    transaction().transactionType === 'refund'
                      ? transaction().formattedType
                      : transaction().formattedChannel
                  " matTooltipPosition="right" [icon]="transactionIcon(transaction())" size="lg"></fa-icon>
              </div>
            </div>

            <div
              class="d-flex align-items-start jusfiy-content-between flex-column flex-fill flex-grow-1 flex-shrink-0">
              <p class="my-0 fs-md-18 fs-mb-16">
                <strong data-test="cardHolderName">{{
                  transaction().cardHolderDetails.name || 'No Name'
                  }}</strong>
              </p>
              <div class="w-100 my-1" style="height: 1px; background: #949494"></div>
              <p class="my-0 fs-md-18 fs-mb-16">
                <strong data-test="maskedCard">{{
                  transaction().maskedCard
                  }}</strong>
              </p>
            </div>
          </div>

          <div
            class="flex-shrink-0 flex-grow-1 mt-3 mt-sm-0 d-flex align-items-start jusfiy-content-between flex-column">
            <p class="my-0 fs-md-18 fs-mb-16">
              <strong data-test="amount">{{
                transaction().amount | currency: transaction().currencyCountry
                }}</strong>
              <span data-test="currency">
                ({{ transaction().currencyCountry }})</span>
            </p>
            <div class="w-100 my-1" style="height: 1px; background: #949494"></div>
            <p class="my-0 fs-md-18 fs-mb-16">
              <strong data-test="createdOn">{{
                transaction().createdOn | date: 'dd/MM/yy HH:mm'
                }}</strong>
            </p>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <div class="main-panel py-3 px-2 rounded-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-6">
                <!-- Main Detail Column -->
                <h2 class="fw-normal lh-1 mb-3">Timeline</h2>

                <!-- Status - timeline -->
                <div>
                  <ng-container *ngFor="let event of timelineEvents; let isLast = last">
                    <div class="d-flex align-items-center justify-content-start mb-3">
                      <div class="p-2 rounded-circle" [ngClass]="{
                          'opacity-75': !isLast,
                          'bg-success': event.status,
                          'bg-danger': !event.status,
                        }" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>

                      <span class="mb-0 ms-2" [ngClass]="{ 'fw-bolder': isLast }">
                        {{ event.title }}
                        <span>{{ event.time | date: 'dd/MM/yy HH:mm' }}</span>
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <!-- Related transactions -->
                <h2 class="fw-normal my-0 lh-1">Related Transactions</h2>
                <p *ngIf="relatedTransactions() && relatedTransactions().length > 0" class="text-muted text-start mx-auto fst-italic lh-1 mt-1 mb-3"><span
                    class="d-none d-md-inline">Click</span><span class="d-inline d-md-none">Tap</span> to view</p>

                <div class="w-100 pe-2" style="max-height: 210px; overflow-y: auto;">
                  <ng-container *ngIf="!relatedTransactions() || relatedTransactions().length === 0">
                    <p class="text-muted text-center mx-auto fst-italic mt-4">No related transactions</p>
                  </ng-container>
                  <ng-container *ngFor="
                      let related of relatedTransactions();
                      let isLast = last
                    ">
                    <button (click)="OpenRelation(related)" [attr.data-test]="'related['+related.crossReference+']'"
                      class="btn rounded-3 mb-2 border shadow-sm text-dark border-2 p-2 w-100" [ngClass]="{
                        'mon-border-success': isSuccess(related),
                        'mon-border-danger': !isSuccess(related)
                      }">
                      <div class="d-flex align-items-center justify-content-start gap-2 mb-1">
                        <small class="fs-6 my-0 lh-sm text-start text-dark">
                          Type/Amount: {{ GetTransactionType(related) }}
                        </small>
                        <small class="fs-6 my-0 lh-sm text-start">
                          {{
                          related.amount | currency: related.currencyCountry
                          }}
                        </small>
                      </div>
                      <small class="d-block text-start mt-0 mb-1 lh-sm">
                        Created At: {{ related.createdOn | date: 'dd/MM/yy HH:mm' }}
                      </small>
                      <small class="d-block text-start my-0 lh-sm">
                        ID: {{ related.crossReference }}
                      </small>
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3" *ngIf="!shelper.isNullOrEmpty(transaction().paymentDetail) && !shelper.isGuid(transaction().paymentDetail)">
          <div class="main-panel py-3 px-2 rounded-3">
            <h2 class="fw-normal my-0 mb-3 lh-1">Payment Detail</h2>
            <div *ngIf="shelper.isJsonB64(transaction().paymentDetail)">

              <table class="table table-striped" style="table-layout: fixed">
                <tbody>
                <tr>
                  <th>RAW Value</th>
                  <td class="overflow-hidden">{{ transaction().paymentDetail }}</td>
                </tr>
                <tr>
                  <th>Parsed Values</th>
                  <th></th>
                </tr>
                <tr *ngFor="let lineItem of CustomJsonLines(transaction().paymentDetail)">
                  <td>{{ lineItem.key }}</td>
                  <td>
                    <strong>{{ lineItem.value }}</strong>
                  </td>
                </tr>
                </tbody>
              </table>

              <details>
                <summary class="py-2 px-3 rounded-3">
                  <h2 class="card-title my-0 d-flex align-items-center justify-content-start gap-2"><mat-icon class="my-0">data_object</mat-icon> Raw JSON</h2>
                </summary>
                <div class="px-2 px-md-3 py-3 bg-light rounded-3">
                  <pre data-test="paymentDetail" class="my-0">{{ shelper.decodeJsonB64(transaction().paymentDetail) | json }}</pre>
                </div>
              </details>
            </div>
            <span *ngIf="!shelper.isJsonB64(transaction().paymentDetail)" data-test="paymentDetail">{{transaction().paymentDetail}}</span>
          </div>
        </div>

        <div class="mt-3" *ngIf="!shelper.isNullOrEmpty(transaction().paymentReference) && !shelper.isGuid(transaction().paymentReference)">
          <div class="main-panel py-3 px-2 rounded-3">
            <h2 class="fw-normal my-0 mb-3 lh-1">Payment Reference</h2>
            <span data-test="paymentReference">{{transaction().paymentReference}}</span>
          </div>
        </div>

        <details *ngIf="featureHelper['extended-transactions'] && LineItems().length > 0" class="mt-3 main-panel rounded-3" open>
          <summary class="py-2 px-3 rounded-3">
            <h2 class="card-title my-0 d-flex align-items-center justify-content-start gap-2"><mat-icon class="my-0">shopping_basket</mat-icon> Basket Details</h2>
          </summary>

          <table class="table table-striped" style="table-layout: fixed">
            <tbody>
              <tr *ngFor="let lineItem of LineItems()">
                <td>{{ lineItem.quantity }}x {{ lineItem.description }}</td>
                <td>
                  <strong [attr.data-test]="'itemValue'">{{ (lineItem.grossValue / 100) | currency: transaction().currencyCountry }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </details>

        <details class="mt-3 main-panel rounded-3" open>
          <summary class="py-2 px-3 rounded-3">
            <h2 class="card-title my-0">Extra Details</h2>
          </summary>

          <table class="table table-striped" style="table-layout: fixed">
            <tbody>
              <tr>
                <td>{{ transactionType }} ID</td>
                <td>
                  <strong [attr.data-test]="'crossRef'">{{ transaction().crossReference }}</strong>
                </td>
              </tr>

              <tr *ngIf="!shelper.isNullOrEmpty(transaction().operatorId)">
                <td>{{ transactionType }} Completed By</td>
                <td>
                  <strong [attr.data-test]="'operatorName'" [attr.data-user-id]="transaction().operatorId">{{
                    transaction().operatorName
                    }}</strong>
                </td>
              </tr>

              <tr>
                <td>{{ transactionType }} Type</td>
                <td class="d-flex align-items-center justify-content-start">
                  <div style="width: 24px">
                    <fa-icon class="d-block mx-auto" [icon]="transactionIcon(transaction())" size="lg"></fa-icon>
                  </div>
                  <strong class="ms-2" [attr.data-test]="'formattedChannel'">{{
                    transaction().formattedChannel
                    }}</strong>
                </td>
              </tr>

              <tr>
                <td>Card Type</td>
                <td class="d-flex align-items-center justify-content-start">
                  <div class="d-flex align-items-center justify-content-center h-100 px-2 rounded"
                    style="background-color: #fff; color: black">
                    <div style="width: 24px; height: 24px">
                      <mat-icon class="h-100 d-block" *ngIf="transaction().cardIcon.iconType === 'ICON'">{{
                        transaction().cardIcon.value }}</mat-icon>
                      <img class="w-100 h-100 d-block" *ngIf="transaction().cardIcon.iconType !== 'ICON'"
                        [src]="transaction().cardIcon.value" />
                    </div>
                  </div>
                  <strong class="ms-2" [attr.data-test]="'cardType'">{{ transaction().cardType }}</strong>
                </td>
              </tr>

              <tr *ngIf="transaction().cardHolderDetails.name">
                <td>Cardholder Name</td>
                <td class="d-flex align-items-center justify-content-start">
                  <strong class="ms-2" [attr.data-test]="'cardHolderName'">{{ transaction().cardHolderDetails.name }}</strong>
                </td>
              </tr>

              <tr *ngIf="transaction().cardHolderDetails.address">
                <td>Cardholder Address</td>
                <td class="d-flex align-items-center justify-content-start">
                  <strong class="ms-2" [attr.data-test]="'cardHolderAddress'">{{ transaction().cardHolderDetails.address }}</strong>
                </td>
              </tr>

              <tr *ngIf="transaction().cardHolderDetails.postcode">
                <td>Cardholder Postcode</td>
                <td class="d-flex align-items-center justify-content-start">
                  <strong class="ms-2" [attr.data-test]="'cardHolderPostcode'">{{ transaction().cardHolderDetails.postcode }}</strong>
                </td>
              </tr>

              <tr *ngIf="transaction().cardHolderDetails.phoneNumber">
                <td>Cardholder Phone Number</td>
                <td class="d-flex align-items-center justify-content-start">
                  <strong class="ms-2" [attr.data-test]="'cardHolderPhone'">{{ transaction().cardHolderDetails.phoneNumber }}</strong>
                </td>
              </tr>

              <tr *ngIf="transaction().cardHolderDetails.emailAddress">
                <td>Cardholder Email</td>
                <td class="d-flex align-items-center justify-content-start">
                  <strong class="ms-2" [attr.data-test]="'cardHolderEmail'">{{ transaction().cardHolderDetails.emailAddress }}</strong>
                </td>
              </tr>

              <tr *ngIf="
                  showRefundAmount() &&
                  transaction().transactionType !== 'refund'
                ">
                <td>Amount Refunded</td>
                <td class="d-flex align-items-center justify-content-start">
                  <mat-icon [matTooltip]="
                      'Value of accepted refunds against this transaction.'
                    " matTooltipPosition="right">info</mat-icon>
                  <strong class="ms-2" [attr.data-test]="'amountRefunded'">{{
                    transaction().amountRefunded || 0
                    | currency: transaction().currencyCountry
                    }}</strong>
                </td>
              </tr>

              <tr *ngIf="
                  transaction().remainingValue !== transaction().amount &&
                  transaction().transactionType !== 'refund' &&
                  transaction().amountRefunded !== undefined
                ">
                <td>Remaining Value</td>
                <td>
                  <strong [attr.data-test]="'remainingValue'">{{
                    transaction().remainingValue
                    | currency: transaction().currencyCountry
                    }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </details>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingSpinner>
  <odin-loading-spinner [active]="true"></odin-loading-spinner>
</ng-template>

<!-- {
    "currencyCode": "826",
    "id": "bde0d657-79f5-4703-907f-d64d2a4a2183",
    "amount": 1.23,
    "amountRefunded": null,
    "crossReference": "24100715335732442HO",
    "isReversed": false,
    "channel": "moto",
    "status": "completed",
    "lastError": null,
    "merchantRef": "00000000-0000-0000-0000-000000000000",
    "maskedCard": "401550******0216",
    "last4Digits": "0216",
    "createdOn": "2024-10-07T15:33:58.6212216+01:00",
    "updatedOn": null,
    "settledOn": null,
    "settlement": null,
    "currencyCountry": "826",
    "cardHolderDetails": {
        "name": null,
        "address": "155",
        "postcode": "16",
        "phoneNumber": null,
        "emailAddress": null
    },
    "transactionType": "sale",
    "paymentReference": "852b4431-ceb7-49b6-afd0-dc257bcddc70",
    "paymentDetail": null,
    "operatorId": null,
    "operatorName": null,
    "originId": "00000000-0000-0000-0000-000000000000"
} -->
