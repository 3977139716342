import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { OdinFeatures } from '@odin/odin-core';

@Component({
  standalone: false,
  selector: 'odin-integrations',
  templateUrl: './odin-integrations.component.html',
  styleUrls: ['./odin-integrations.component.scss'],
})
export class OdinIntegrationsComponent {
  constructor(@Inject('features') features: OdinFeatures,
    private router: Router
  ) {
    if (!features['integrations']) this.router.navigate([`/dashboard`])
  }
}
