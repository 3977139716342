import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, concat, fromEvent, Subject } from 'rxjs';
import { ISettlement, ScrollDirection } from '../models';
import { MatSidenav } from '@angular/material/sidenav';
import { fadeAnimation } from '../animations';
import { map } from 'rxjs/operators';
import * as GLOBALS from '../settlements-global.const.values';
import { SettlementsFacade } from '../../+state/settlements/settlements.facade';
import { RouterExtService, SettlementsService } from '../services';
import * as moment from 'moment';
import { LOADED, MatchMediaService } from '@odin/odin-core';

const loaded$ = new Subject<void>();

@Component({
  standalone: false,
  selector: 'odin-settlement-list-page',
  templateUrl: './settlement-list-page.component.html',
  styleUrls: ['./settlement-list-page.component.scss'],
  animations: [fadeAnimation],
  providers: [
    {
      provide: LOADED,
      useValue: loaded$.asObservable(),
    },
  ],
})
export class SettlementListPageComponent implements OnInit, AfterViewInit {
  settlements$!: Observable<ISettlement[]>;
  error$!: Observable<string>;
  allowSelection$!: Observable<boolean>;
  loading$!: Observable<boolean>;
  hasNext$!: Observable<boolean>;
  hasPrev$!: Observable<boolean>;
  noMoreToLoad$!: Observable<boolean>;
  scroll$!: Observable<boolean>;

  isDesktopLayout = true;
  tabletQuery!: MediaQueryList;
  mobileQuery!: MediaQueryList;
  isTouch = false;
  colour = 'primary';

  dailySettlementSelected = true;
  monthlySettlementSelected = false;

  // use prop in the test spec file
  activatedRoute!: ActivatedRoute;

  @ViewChild('drawer') filters_sidenav!: MatSidenav;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private facade: SettlementsFacade,
    private mediaService: MatchMediaService,
    private settlementService: SettlementsService,
    private routerExtService: RouterExtService,
  ) {
    this.isTouch = this.mediaService.isTouch();
    this.tabletQuery = this.mediaService.matchMedia(GLOBALS.MAX_SCREEN_WIDTH);
    this.mobileQuery = this.mediaService.matchMedia(GLOBALS.MIN_SCREEN_WIDTH);

    this.activatedRoute = route;
  }

  ngAfterViewInit(): void {
    const content = document.querySelector(GLOBALS.SCROLLED_CONTAINER);

    if (content) {
      this.scroll$ = fromEvent(content, 'scroll').pipe(
        map(() => content.scrollTop > GLOBALS.SCROLLED_TOP),
      );
    }
  }

  ngOnInit(): void {
    if (!this.routerExtService.redirectFromSettlementDetails()) {
      // call service if it's not a redirect from settlement details page
      this.facade.getSettlements();
    }

    this.settlements$ = this.facade.loadSettlements$;
    this.loading$ = this.facade.getSettlementsLoader$;

    // get allow selection
    this.allowSelection$ = this.facade.getAllowSelection$;

    // catch error if any
    this.error$ = this.facade.getError$;

    // get pagination values
    this.hasNext$ = this.facade.getHasNext$;
    this.hasPrev$ = this.facade.getHasPrev$;
    this.noMoreToLoad$ = this.facade.noMoreToLoad$;
  }

  navigateToDetails(settlement: ISettlement) {
    if (settlement !== null) {
      this.settlementService.setSelectedSummary(settlement);
      const shortDate = moment(settlement.settledDay).format('YYYY-MM-DD');
      this.router.navigate([`${shortDate}`], { relativeTo: this.route });
    }
  }

  loadSettlementsOnScroll(direction: ScrollDirection) {
    if (direction === ScrollDirection.Down) {
      this.loadMoreSettlements();
    }
  }

  public loadMoreSettlements(): void {
    // eslint-disable-next-line no-var
    var sub = this.loading$.subscribe((_) => {
      if (sub) sub.unsubscribe();
      if (!sub && !_) {
        // eslint-disable-next-line no-var
        var _sub = this.noMoreToLoad$.subscribe((nomoreToLoad: boolean) => {
          if (_sub) _sub.unsubscribe();
          if (nomoreToLoad) return;
          this.facade.loadMoreSettlements();
          const moreSettlements$ = this.facade.loadMoreSettlements$;
          this.settlements$ = concat(this.settlements$, moreSettlements$);
        });
      }
    });
  }

  public onChangeSettlementType(settlementSelectionEvent: string) {
    if (settlementSelectionEvent === 'dailySettlementSelected') {
      this.dailySettlementSelected = true;
      this.monthlySettlementSelected = false;
    } else if (settlementSelectionEvent === 'monthlySettlementSelected') {
      this.dailySettlementSelected = false;
      this.monthlySettlementSelected = true;
    }
  }

  onNewMonthlySettlementReport(monthlyReportDate: Date) {
    this.facade.getMonthlySettlementReport(monthlyReportDate);
  }

  onNewMonthlySettlementReportCSV(monthlyReportDate: Date) {
    this.facade.getMonthlySettlementReportCSV(monthlyReportDate);
  }
}
