import { Component, Input } from '@angular/core';
import CountryConvertService from '../data/country-convert';

export class ReceiptDetail {
  constructor(
    public transactionName: string,
    public traceId: string,
    public custName: string,
    public detail: string,
    public time: Date,
    public authCode: string,
    public amount: number,
    public currencyCode: string,
  ) {
    this.getISOFromCode();
  }

  public getISOFromCode(): void {
    const newIso = new CountryConvertService().GetCountryByCurrencyCode(
      this.currencyCode,
    );
    if (newIso === undefined)
      this.currencyCode = 'GBP'; // default currency fallback
    else this.currencyCode = newIso.currencyCode;
  }
}

@Component({
  standalone: false,
  selector: 'odin-print-receipt',
  templateUrl: './print-receipt.component.html',
  styleUrls: ['./print-receipt.component.css'],
})
export class PrintReceiptComponent {
  @Input() public receiptDetail?: ReceiptDetail = undefined;
}
