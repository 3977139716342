import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccessGuardService } from '@odin/odin-authentication';
import {
  FormData,
  FormBuilder,
  Size,
  MonekColour,
  Alignment,
  InputType,
  FormValidation,
  ValidationPattern,
  VTService,
  NotificationService,
  CardHelperService,
  InputIconType,
  StaticHelpers,
  FormField,
  MerchantService,
  OptionInputType,
  FormOption,
} from '@odin/odin-core';
import { InputPrefixSuffixType } from '@odin/odin-core';
import { MerchantChangeService } from '@odin/odin-core';
import {
  ReceiptDetail,
  VTPaymentRequest,
  VTPaymentResponse,
} from '@odin/odin-transactions';
import { Subject, Subscription, takeUntil } from 'rxjs';

enum VTPage {
  payment = 'payment',
  success = 'success',
  failed = 'failed',
}

@Component({
  standalone: false,
  selector: 'odin-virtual-terminal-page',
  templateUrl: './virtual-terminal-page.component.html',
  styleUrls: ['./virtual-terminal-page.component.scss'],
})
export class VirtualTerminalPageComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public virtualTerminalForm: FormData<VTPaymentRequest> = this.BuildForm();
  public lastRequest: VTPaymentRequest;
  public paymentResponse: VTPaymentResponse;
  public processing = false;
  public page: VTPage = VTPage.payment;
  public retryCount: number = 0;
  public transactionBuidName: string;
  public receiptDetail?: ReceiptDetail;

  public printing: boolean = false;

  private cardInputIcon: InputPrefixSuffixType = new InputPrefixSuffixType(
    InputIconType.ICON,
    'credit_card',
  );

  private merchantChangeSub: Subscription;

  constructor(
    private vtService: VTService,
    private cardService: CardHelperService,
    private notificationservice: NotificationService,
    private accessService: AccessGuardService,
    private merchantService: MerchantService,
    private merchantChangeService: MerchantChangeService,
  ) {}

  ngOnInit(): void {
    this.merchantChangeSub =
      this.merchantChangeService.merchantChangeEvent.subscribe(() => {
        if (!this.GrantChecker()) {
          this.accessService.boot(
            'User does not have access to Virtual Terminal. Please contact an administrator',
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.merchantChangeSub !== undefined)
      this.merchantChangeSub.unsubscribe();
  }

  public GrantChecker(): boolean {
    return this.accessService.hasGrant('txn:Create');
  }

  public ResetPage(clearForm: boolean): void {
    this.lastRequest.IdempotencyToken = StaticHelpers.uuidv4(); // reset
    if (clearForm) {
      this.virtualTerminalForm = this.BuildForm();
      this.retryCount = 0;
      this.lastRequest = new VTPaymentRequest(); // clears data out of memory, card number etc
      this.receiptDetail = undefined;
    }
    this.page = VTPage.payment;
  }

  public RetryPayment(): void {
    if (this.page === VTPage.failed) {
      this.lastRequest.IdempotencyToken = StaticHelpers.uuidv4(); // reset
      this.SubmitAction(this.lastRequest);
      this.retryCount++;
    }
  }

  private SubmitAction(data: VTPaymentRequest): void {
    // somehow got past the ui validation, clearing the form
    if (this.retryCount >= 3) {
      this.ResetPage(true);
      return;
    }

    this.lastRequest = data;
    this.processing = true;
    this.vtService
      .SubmitPaymentRequest(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp: VTPaymentResponse) => {
          this.processing = false;
          this.paymentResponse = resp;
          resp.time = new Date();
          this.receiptDetail = undefined;
          if (resp.status) {
            // remove confidential details
            this.lastRequest.CardNumber = '';
            this.lastRequest.CV2 = '';
            const currentBuid = this.merchantService.getCurrentMerchant();
            if (currentBuid !== null) {
              this.transactionBuidName = currentBuid.name;
            }
            this.receiptDetail = new ReceiptDetail(
              this.transactionBuidName,
              this.paymentResponse.traceId,
              this.lastRequest.QAName,
              this.lastRequest.PaymentDetail,
              this.paymentResponse.time,
              this.paymentResponse.message,
              this.lastRequest.Amount,
              this.lastRequest.CurrencyCode,
            );
            this.page = VTPage.success;
          } else {
            this.page = VTPage.failed;
          }
        },
        () => {
          // request failed, unknown response
          this.receiptDetail = undefined;
          this.processing = false;
          this.notificationservice.SmallDialog(
            'An unknown error occured',
            'Close',
            3000,
          );
        },
      );
  }

  private BuildForm(): FormData<VTPaymentRequest> {
    this.cardInputIcon = new InputPrefixSuffixType(
      InputIconType.ICON,
      'credit_card',
    );
    const userProfile = this.merchantService.getUserprofile();
    const formData = new FormBuilder<VTPaymentRequest>(MonekColour.Primary)
      .ToggleSubmitButton(true)
      .SubmitButtonConfig('Confirm', Size.Full, Alignment.Left)

      //Hidden Fields
      .AddFormField(
        'OperatorId',
        '',
        InputType.Hidden,
        userProfile?.userId || '',
        Size.Full,
      )
      .AddFormField(
        'OperatorName',
        '',
        InputType.Hidden,
        userProfile?.email || '',
        Size.Full,
      )

      .AddFormField('MerchantID', '', InputType.Hidden, '', Size.Full)
      .AddFormField('CountryCode', '', InputType.Hidden, '826', Size.Full)
      .AddFormField('ResponseAction', '', InputType.Hidden, 'JSON', Size.Full)
      .AddFormField('QAProducts', '', InputType.Hidden, '', Size.Full)
      .AddFormField(
        'IdempotencyToken',
        '',
        InputType.Hidden,
        StaticHelpers.uuidv4(),
        Size.Full,
      )

      //Potential Prompts
      .AddFormField(
        'MessageType',
        '',
        InputType.Hidden,
        'SALE_CNP_TO',
        Size.Full,
      )
      .AddFormField('Dispatch', '', InputType.Hidden, 'NOW', Size.Full)
      .AddFormField('CurrencyCode', '', InputType.Hidden, '826', Size.Full)

      .AddSection('Payment Details') // PAYMENT DETAIL SECTION HEADER
      .AddFormField(
        'Amount',
        'Transaction Amount (£0.00)',
        InputType.Number,
        null,
        Size.Full,
        new FormValidation()
          .Required()
          .MinValue(0.01, 'Minimum transaction value is £0.01')
          .MaxValue(999999.99, 'Maxmimum transaction value is £999,999.99')
          .PatternMatch(ValidationPattern.Decimal, 'Amount must be a number'),
        false,
        {
          prefixIcon: new InputPrefixSuffixType(
            InputIconType.ICON,
            'currency_pound',
          ),
        },
        undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formfield: FormField, value: any) => {
          if (value !== '') {
            const numericValue = parseFloat(value);
            formfield.value = numericValue.toFixed(2);
            if (isNaN(value)) formfield.value = '0.00';
          }
        },
      )
      .AddFormField(
        'PaymentDetail',
        'Description',
        InputType.Text,
        '',
        Size.Full,
        new FormValidation().MaxLength(
          30,
          'Detail can not exceed 30 characters',
        ),
        false,
        {
          prefixIcon: new InputPrefixSuffixType(InputIconType.ICON, 'sell'),
        },
      )

      .AddSection('Card Details') // PAYMENT DETAIL SECTION HEADER
      .AddFormField(
        'QAName',
        'Cardholder Name',
        InputType.Text,
        '',
        Size.Full,
        new FormValidation().Required(),
        false,
        {
          prefixIcon: new InputPrefixSuffixType(InputIconType.ICON, 'face'),
        },
      )
      .AddFormField(
        'CardNumber',
        'Card Number',
        InputType.Text,
        '',
        Size.Full,
        new FormValidation()
          .PatternMatch(
            ValidationPattern.Numeric,
            'Please enter a valid card number using only numbers',
          )
          .MinLength(8, 'Card number must be between 8 - 20 characters')
          .MaxLength(20, 'Card number must be 8 - 20 characters')
          .Required(),
        false,
        {
          prefixIcon: this.cardInputIcon,
          class: 'card-font',
        },
        (
          val: string | number | boolean | Date | null,
          data: { prefixIcon: InputPrefixSuffixType; class: string },
          dataCallback: (data: {
            prefixIcon: InputPrefixSuffixType;
            class: string;
          }) => void,
          valCallback: (data: string | number | boolean | Date | null) => void,
        ) => {
          if (typeof val !== 'string') return;
          valCallback(val);
          const cardType = this.cardService.GetCardType(val);
          this.cardInputIcon = this.cardService.GetCardIcon(cardType);
          const updatedData = data;
          updatedData.prefixIcon = this.cardInputIcon;
          dataCallback(updatedData);
        },
      )
      .AddFormField(
        'CV2',
        'CV2',
        InputType.Text,
        '',
        Size.Half,
        new FormValidation()
          .Required()
          .MinLength(3, 'Invalid CV2')
          .MaxLength(4, 'Invalid CV2'),
        false,
        {
          prefixIcon: new InputPrefixSuffixType(InputIconType.ICON, 'security'),
        },
      )
      .AddFormField(
        'CardExpiryMonth',
        'Card Expiry Month (MM)',
        InputType.Text,
        null,
        Size.Quater,
        new FormValidation().ExactLength(2).MinValue(1).MaxValue(12).Required(),
      )
      .AddFormField(
        'CardExpiryYear',
        'Card Expiry Year (YY)',
        InputType.Text,
        null,
        Size.Quater,
        new FormValidation().ExactLength(2).Required(),
      )
      .AddDetail('Accept Card on File')
      .AddOptionField(
        'AcceptCof',
        'Accept Card on File',
        OptionInputType.Checkbox,
        null,
        [
          new FormOption('Y', 'Yes'),
          new FormOption('N', 'No'),
          new FormOption(null, 'N/A'),
        ],
        Size.Full,
        new FormValidation(),
        true,
      )

      .Build((data) => this.SubmitAction(data));

    return formData;
  }

  public PrintReceipt(): void {
    print();
  }
}
