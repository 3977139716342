import { environment } from "../../environments/environment";

export class Branding {
    name: string;
    logoUrl: string;
    brandName: string;
    hasTextLogo: boolean;
    textLogoClass: string;
    fixedTheme: boolean
    themeClass: string;
    availableThemes: { view: string, value: string }[];
    logoWidth: string;
    logoHeight: string;
}

export class BrandingHelper {
    public static currentBranding(): Branding {
        const builtBrand = environment.selectedBrandName;
        const brandToReturn = environment.brandings.find((b: Branding) => b.name === builtBrand);
        if (brandToReturn === undefined) return environment.brandings[0]    // default is MONEK
        return brandToReturn;
    }
}