import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OdinIntegrationsComponent } from './odin-integrations.component';

export const routes: Routes = [
  { path: '', component: OdinIntegrationsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OdinIntegrationsRoutingModule {}
