import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { XeroIntegrationsService } from '../../services/xero-integrations-service.service';
import { catchError, Observable, forkJoin, throwError, EMPTY } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Account, AccountFilter, BrandingTheme, PaymentServiceSetupForm } from '../../models/xero';
import { MerchantChangeService, NotificationService } from '@odin/odin-core';

@Component({
  standalone: false,
  selector: 'odin-integrations-xero-paymentservices-page',
  templateUrl: './integrations-xero-paymentservices-page.component.html',
  styleUrl: './integrations-xero-paymentservices-page.component.css',
})
export class IntegrationsXeroPaymentServicesPageComponent implements OnInit {
  themes: WritableSignal<BrandingTheme[]> = signal([]);
  paymentAccounts: WritableSignal<Account[]> = signal([]);
  feeAccounts: WritableSignal<Account[]> = signal([]);
  loading: WritableSignal<boolean> = signal(true);

  themeControl: FormControl = new FormControl();
  paymentAccountControl: FormControl = new FormControl();
  feeAccountControl: FormControl = new FormControl();

  tenantId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private xeroIntegrationsService: XeroIntegrationsService,
    private merchantChangeService: MerchantChangeService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.merchantChangeService.merchantChangeEvent.subscribe(() => {
      this.router.navigate(['/integrations']);
    });

    this.tenantId = this.route.snapshot.paramMap.get("tenantId") || "";

    if (this.tenantId === "") {
      this.router.navigate(["/integrations"]);
    }

    this.loadSetupForm();
  }

  cancel(): void {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

  submit() {
    const paymentBody: PaymentServiceSetupForm = {
      buid: '',
      themeId: this.themeControl.value,
      paymentId: this.paymentAccountControl.value,
      feeId: this.feeAccountControl.value,
      tenantId: this.tenantId
    };

    this.xeroIntegrationsService.createPaymentService(paymentBody)
      .pipe(catchError(error => this.handleBadRequest(error)))
      .subscribe(() => {
        this.router.navigate([".."], {relativeTo:this.route});
      });
  }

  private loadSetupForm(): void {
    this.loading.set(true);

    forkJoin([
      this.xeroIntegrationsService.getBrandingThemes(this.tenantId),
      this.xeroIntegrationsService.getAccounts(this.tenantId, AccountFilter.Payment),
      this.xeroIntegrationsService.getAccounts(this.tenantId, AccountFilter.Fee)
    ])
    .pipe(catchError(error => this.handleUnauthorized(error)))
    .subscribe(results => {
      const themes = results[0];
      this.themes.set(themes);

      if (themes.length) {
        this.themeControl.setValue(themes[0].id);
      }

      const paymentAccounts = results[1];
      this.paymentAccounts.set(paymentAccounts);

      if (paymentAccounts.length) {
        this.paymentAccountControl.setValue(paymentAccounts[0].id);
      }

      const feeAccounts = results[2];
      this.feeAccounts.set(feeAccounts);

      if (feeAccounts.length) {
        this.feeAccountControl.setValue(feeAccounts[0].id);
      }

      this.loading.set(false);
    });
  }

  private handleUnauthorized(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      this.router.navigate(["/integrations"]);
      return EMPTY;
    } 
    // Return an observable with a user-facing error message.
    return throwError(() => error);
  }

  private handleBadRequest(error: HttpErrorResponse): Observable<never> {
    if (error.status === 400) {
      this.notificationService.AlertDialogWindow(error.error);
      return EMPTY;
    }

    return throwError(() => error);
  }
}
