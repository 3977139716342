import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ConfirmationService,
  NotificationService,
  VTService,
} from '@odin/odin-core';
import {
  PEBLFormData,
  PEBLPaymentRequest,
  PEBLPaymentResponse,
  PeblSendType,
} from '@odin/odin-transactions';

@Component({
  standalone: false,
  selector: 'odin-pebl-confirmation-dialog',
  templateUrl: './pebl-confirmation-dialog.component.html',
  styleUrls: ['./pebl-confirmation-dialog.component.css'],
})
export class PeblConfirmationDialogComponent {
  public processing: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<PeblConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public peblFormData: PEBLFormData,
    private vtService: VTService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
  ) { }

  public SendTypeMapper(sendtype: PeblSendType): string {
    switch (sendtype) {
      case PeblSendType.EMAIL:
        return `Email - ${this.peblFormData.emailAddress}`;
      case PeblSendType.SMS:
        return `SMS - ${this.peblFormData.phoneNumber}`;
      case PeblSendType.LINK:
        return 'Direct Link';
    }
    return 'Unknown - Error';
  }

  public LinkExpiryMapper(time: string): string {
    switch (time) {
      case '60':
        return '1 Hour';
      case '1440':
        return '24 Hours';
      case '10080':
        return '1 Week';
      case '43800':
        return '1 Month';
    }
    return 'Unknown - Error';
  }

  public MakePEBLRequest(): void {
    this.processing = true;
    const reqBody = new PEBLPaymentRequest().build(this.peblFormData);
    this.vtService.CreatePeblRequest(reqBody).subscribe(
      (_: PEBLPaymentResponse) => {
        this.processing = false;
        this.notificationService.SmallDialog(_.message, 'Close', 10000);
        this.dialogRef.close({ status: true, resp: _, errors: [] });
      },
      (err: HttpErrorResponse) => {
        this.processing = false;
        // handle errors
        const error = err.error as {
          message: string;
          errors: { errorMessage: string; propertyName: string }[];
        };
        if (error.errors === undefined) {
          this.notificationService.SmallDialog(
            'Something went wrong.',
            'Close',
            10000,
          );
          return;
        }
        if (error.errors.length > 0) {
          // handle multiple errors
          this.notificationService.SmallDialog(
            'Multiple errors detected.',
            'Close',
            10000,
          );
        } else {
          this.notificationService.SmallDialog(
            `${error.message}`,
            'Close',
            10000,
          );
        }
        this.dialogRef.close({ status: false, errors: error.errors });
      },
    );
  }
}
