import { Component } from '@angular/core';
import { Router } from '@angular/router';
import MerchantPCI from '../models/merchant-pci';
import { MerchantChangeService } from '../services/merchant-change.service';
import { MerchantPCIService } from '../services/merchant-pci.service';
import { MerchantService } from '../services/merchant.service';

@Component({
  standalone: false,
  selector: 'odin-pci-widget',
  templateUrl: './pci-widget.component.html',
  styleUrls: ['./pci-widget.component.css'],
})
export class PciWidgetComponent {
  public loading: boolean = true;
  public compliant: boolean = false;
  public docUrl: string | undefined = undefined;

  constructor(
    private router: Router,
    private merchantService: MerchantService,
    private merchantChangeService: MerchantChangeService,
    private merchantPCIService: MerchantPCIService,
  ) {
    this.merchantChangeService.merchantChangeEvent.subscribe(() => {
      this.GetPCIStatus();
    });
    this.GetPCIStatus();
  }

  public merchantName(): string {
    return this.merchantService.getCurrentMerchantName() || 'Current Location';
  }

  private GetPCIStatus(): void {
    this.loading = true;
    this.docUrl = undefined;
    this.merchantPCIService.GetPCIForMerchant().subscribe(
      (pciStatus: MerchantPCI) => {
        this.loading = false;
        this.compliant = true;
        this.docUrl = pciStatus.pci_doc_url;
      },
      () => {
        this.loading = false;
        this.compliant = false;
      },
    );
  }

  public DownloadPCI(): void {
    if (this.docUrl === undefined) return;
    window.open(this.docUrl, '_blank');
  }

  public ReviewPCI(): void {
    this.router.navigate(['/dashboard/pci']);
  }
}
