<div class="p-3 page-height overflow-auto">
  <mat-card>
    <mat-card-content *ngIf="!loading()">
      <h2>Manage Xero Integrations</h2>
      <div class="mt-3 mb-0 hr-light"></div>
      <table mat-table [dataSource]="tenantBindings()">
        <ng-container matColumnDef="xeroTenantName">
          <th mat-header-cell *matHeaderCellDef>
            <div class="d-flex justify-content-between align-items-center">
              <p class="mb-0">Xero Organisation</p>
              <button mat-flat-button class="me-1 mon-text-danger border mon-border-danger" (click)="deleteAllBindings()"><mat-icon>link_off</mat-icon> Unlink All</button>
            </div>
          </th>
          <td mat-cell *matCellDef="let binding">
            <div class="d-flex justify-content-between align-items-center">
              <p class="mb-0">{{binding.tenantName}}</p>
              <button mat-flat-button class="me-1 mon-text-danger mon-border-danger border icon-button" (click)="deleteBinding(binding.tenantId)"><mat-icon>link_off</mat-icon></button>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="paymentService">
          <th mat-header-cell *matHeaderCellDef>Payment Service</th>
          <td mat-cell *matCellDef="let binding">
            <p *ngIf="!allowPaymentServiceConfigure(binding)" class="mb-0">{{getPaymentServiceStatus(binding)}}</p>
            <button *ngIf="allowPaymentServiceConfigure(binding)" mat-button class="me-1 mon-border-primary mon-bg-primary border mon-text-primary" (click)="configurePaymentService(binding.tenantId)"><mat-icon>add</mat-icon>Create</button>
          </td>
        </ng-container>
        <ng-container matColumnDef="addConnection">
          <td mat-footer-cell *matFooterCellDef>
            <button mat-flat-button class="p-3 me-1 mon-text-primary mon-bg-primary mon-border-primary border" (click)="connectXero()"><mat-icon>add_link</mat-icon>Connect another Xero organisation</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['xeroTenantName', 'paymentService']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['xeroTenantName', 'paymentService']"></tr>
        <tr mat-footer-row *matFooterRowDef="['addConnection']"></tr>
      </table>
      <button mat-flat-button class="mt-3 me-1 border" (click)="back()"><mat-icon>arrow_back</mat-icon>Back</button>
    </mat-card-content>
    <odin-loading-spinner class="mt-3 mb-3" [active]="loading()"></odin-loading-spinner>
  </mat-card>
</div>