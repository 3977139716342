import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TransactionFiltersInitial } from '../../../models';
import { DateValidator } from '../validators/custom.date.validator';
import {
  DATE_MIN,
  NUMBER_MAX_VALUE,
  NUMBER_MIN_VALUE,
} from '../filters.const.values';
import { AmountValidator } from '../validators/custom.amount.validator';
import { debounceTime } from 'rxjs/operators';
import { faCoins, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ReportsDialogComponentModel } from "../../csv-reports-dialog/reports-dialog-component-model";
import { TransactionFormatterService } from '../../../services/transaction-formatter.service';

@Component({
  standalone: false,
  selector: 'odin-transactions-filters-form',
  templateUrl: './transactions-filters.component.html',
  styleUrls: ['./transactions-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsFiltersComponent implements OnInit {
  min_date: Date = DATE_MIN;
  max_date: Date = new Date();

  channels: string[] = [];
  statuses: string[] = [];

  transactionsFiltersForm!: FormGroup;
  filtersObj!: TransactionFiltersInitial;

  faCoins = faCoins;
  faDownload = faDownload;

  @Input() set transactionFiltersInitial(value: TransactionFiltersInitial) {
    // create a new instance of the class to access helper methods
    this.filtersObj = new TransactionFiltersInitial(
      value.minDate,
      value.maxDate,
      value.minAmount,
      value.maxAmount,
      value.channel || 'All',
      value.status || 'All',
      value.currencyCode
    );

    // we might not need this (it's useful when amount is in minor version)
    this.filtersObj.convertToMajorCurrency();

    this.createForm();
  }

  @Output() transactionFiltersChanged: EventEmitter<TransactionFiltersInitial> =
    new EventEmitter<TransactionFiltersInitial>();

  @Output() csvReport: EventEmitter<ReportsDialogComponentModel> = new EventEmitter<ReportsDialogComponentModel>();

  constructor(
    private fb: FormBuilder,
    private transactionFormatterService: TransactionFormatterService
  ) {}

  ngOnInit(): void {
    this.channels = [
      'All',
      ...this.transactionFormatterService.getChannelArray(),
    ];
    this.statuses = [
      'All',
      ...this.transactionFormatterService.getStatusArray(),
    ];

    this.changeFormState();
  }

  private createForm() {
    const pattern = '^-?[0-9]\\d*(\\.\\d{1,2})?$';

    this.transactionsFiltersForm = this.fb.group(
      {
        date_min: [this.filtersObj.minDate],
        date_max: [this.filtersObj.maxDate],
        amount_min: [
          this.filtersObj.minAmount,
          [
            Validators.min(NUMBER_MIN_VALUE),
            Validators.max(NUMBER_MAX_VALUE),
            Validators.pattern(pattern),
          ],
        ],
        amount_max: [
          this.filtersObj.maxAmount,
          [
            Validators.min(NUMBER_MIN_VALUE),
            Validators.max(NUMBER_MAX_VALUE),
            Validators.pattern(pattern),
          ],
        ],
        channel: [this.filtersObj.channel],
        status: [this.filtersObj.status],
      },
      {
        validators: [
          DateValidator.confirmed('date_min', 'date_max'),
          AmountValidator.confirmed('amount_min', 'amount_max'),
        ],
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  applyFilters(formValues: any) {
    // if All selected set values to undefined
    formValues.status =
      formValues.status === 'All' ? undefined : formValues.status;
    formValues.channel =
      formValues.channel === 'All' ? undefined : formValues.channel;

    const newTransactionFilters = new TransactionFiltersInitial(
      this.transactionFormatterService.convertToDate(formValues.date_min),
      this.transactionFormatterService.convertToDate(formValues.date_max),
      formValues.amount_min,
      formValues.amount_max,
      formValues.channel,
      formValues.status,
      this.filtersObj.currencyCode
    );

    // convert the amounts in minor version
    newTransactionFilters.convertToMinorCurrency();

    this.transactionFiltersChanged.emit(newTransactionFilters);
  }

  clear() {
    this.transactionsFiltersForm.reset();
    this.transactionFiltersChanged.emit(undefined);
  }

  // reset end date if it's less than the start date
  startDateChanged() {
    if (
      this.transactionsFiltersForm.controls['date_max'].value &&
      this.transactionsFiltersForm.controls['date_max'].value <
        this.transactionsFiltersForm.controls['date_min'].value
    )
      this.transactionsFiltersForm.controls['date_max'].reset();
  }

  displayAmountError(errors: ValidationErrors) {
    if (errors['min'])
      return `Minimum value is <strong>${NUMBER_MIN_VALUE}</strong>`;
    if (errors['max'])
      return `Maximum value is <strong>${NUMBER_MAX_VALUE}</strong>`;
    if (errors['pattern'])
      return `Amount can only have maximum 2 decimal places`;
    if (errors['amountInvalid']) {
      const amount_min = this.transactionsFiltersForm.controls['amount_min'].value;
      return `Value cannot be less than ${amount_min}`;
    }

    return `Value is not valid`;
  }

  csvReportDownload() {
    let csvMinDate: Date = this.transactionFormatterService.convertToDate(this.transactionsFiltersForm.controls['date_min'].value);
    let csvMaxDate: Date = this.transactionFormatterService.convertToDate(this.transactionsFiltersForm.controls['date_max'].value);

    csvMinDate = csvMinDate ? csvMinDate :
    csvMaxDate ? csvMaxDate :
      new Date();

      csvMaxDate = csvMaxDate ? csvMaxDate : new Date();

    const reportsDialogComponentModel = new ReportsDialogComponentModel(
      csvMinDate,
      csvMaxDate
    );

    this.csvReport.emit(reportsDialogComponentModel);
  }

  // check if form got to its default value and mark it as pristine
  private changeFormState() {
    const formDefaultValue = this.transactionsFiltersForm.value;
    this.transactionsFiltersForm.valueChanges
      .pipe(debounceTime(200))
      .subscribe((value) => {
        if (JSON.stringify(formDefaultValue) == JSON.stringify(value)) {
          this.transactionsFiltersForm.markAsPristine();
        }
      });
  }
}
