import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { faAngleRight, faPiggyBank } from '@fortawesome/free-solid-svg-icons';
import { ISettlement, ScrollDirection } from '../../models';
import { take } from 'rxjs';
import { PaginationHelperService } from 'libs/odin-core/src/lib/services/pagination-helper.service';
import { RouterExtService } from '../../services/routing.service';
import { SettlementsFacade } from '../../../+state/settlements/settlements.facade';

@Component({
  standalone: false,
  selector: 'odin-settlements-list',
  templateUrl: './settlements-list.component.html',
  styleUrls: ['./settlements-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettlementsListComponent implements AfterViewInit, OnInit {
  public rowSize = 56;
  @ViewChild(CdkVirtualScrollViewport)
  virtualScroll: CdkVirtualScrollViewport | null = null;

  @Input() settlements: ISettlement[] = [];

  scrollDisable = false;
  @Input() set loading(value: boolean | null) {
    this.scrollDisable = value || false;
  }

  @Input() allowSelection: boolean | null = true;
  @Input() hasNext: boolean | null = false;
  @Input() hasPrev: boolean | null = false;
  @Output() navigateToDetails: EventEmitter<ISettlement> =
    new EventEmitter<ISettlement>();
  @Output() scrollDirection: EventEmitter<ScrollDirection> =
    new EventEmitter<ScrollDirection>();

  faAngleRight = faAngleRight;
  faSettled = faPiggyBank;

  infiniteScrollDistance = 1.4;
  infiniteScrollThrottle = 150;
  fromRoot = true;

  constructor(
    private paginationHelper: PaginationHelperService,
    private routerExtService: RouterExtService,
    private facade: SettlementsFacade
  ) { }

  ngOnInit(): void {
    if (!this.routerExtService.redirectFromSettlementDetails()) {
      this.facade.settlementsScrollPosition(0);
    }
  }

  public ngAfterViewInit(): void {
    if (!this.virtualScroll) return;

    let posToScroll: number;

    this.facade.getScrollPosition$
      .pipe(take(1))
      .subscribe((scrollPosition: number) => {
        posToScroll = scrollPosition || 0;

        setTimeout(() => {
          if (!this.virtualScroll) return;
          this.virtualScroll.scrollToOffset(posToScroll);
        }, 500);
      });

    this.virtualScroll.elementScrolled().subscribe(() => {
      if (!this || !this.virtualScroll) return;

      const distFromBottom = this.virtualScroll.measureScrollOffset('bottom');

      this.paginationHelper.detectEndOfScrollRaw(
        distFromBottom,
        this.rowSize,
        5,
        () => {
          if (this.hasNext) {
            this.scrollDirection.emit(ScrollDirection.Down);
          }
        }
      );
    });
  }

  select(settlement: ISettlement): void {
    if (!this.allowSelection) return;
    if (
      settlement.grossAmount === 0 &&
      settlement.totalFeeAmount === 0 &&
      settlement.netAmount === 0
    ) return;
    if (this.virtualScroll == null) return;

    const scrollTop = this.virtualScroll.measureScrollOffset('top');
    this.facade.settlementsScrollPosition(scrollTop);
    this.navigateToDetails.emit(settlement);
  }
}
