import {
  faCircleExclamation,
  faInfoCircle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { IAnnoucement } from '@odin/odin-core';

enum IAnnoucementType {
  MESSAGE = 'Message',
  WARNING = 'Warning',
  ERROR = 'Error',
}

const MessageIcon = faInfoCircle;
const WarningIcon = faWarning;
const ErrorIcon = faCircleExclamation;

export const messageArray: IAnnoucement[] = [
  {
    id: 0,
    icon: MessageIcon,
    type: IAnnoucementType.MESSAGE,
    date: new Date('22/01/25'),
    message: `We're excited to announce that users can now update their 2FA information directly within the application for enhanced security and convenience. Click <a href="https://docs.monek.com/releasenotes/monekportal/monekportal-01-25-01">here</a> to find out more.`,
  },
];
