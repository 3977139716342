import { Component } from '@angular/core';
import { BUID, MerchantService } from '@odin/odin-core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MerchantChangeService } from '@odin/odin-core';

@Component({
  standalone: false,
  selector: 'odin-merchant-picker',
  templateUrl: './merchant-picker.component.html',
  styleUrls: ['./merchant-picker.component.scss'],
})
export class MerchantPickerComponent {
  public currentMerchantId: string = this.merchantService.getMerchantRef();
  public allMerchants: BUID[] = this.merchantChangeService.getMerchantRefs();
  public currentMerchant(): BUID | null {
    if (this.currentMerchantId === '') return null;
    return (
      this.allMerchants.find((m) => m.id == this.currentMerchantId) || null
    );
  }

  constructor(
    private merchantChangeService: MerchantChangeService,
    private merchantService: MerchantService,
    private snackbar: MatSnackBar,
  ) {
    this.merchantChangeService.merchantChangeEvent.subscribe((id: string) => {
      this.allMerchants = this.merchantChangeService.getMerchantRefs();
      this.currentMerchantId = id;
    });
  }

  public optionChanged(event: MatSelectChange): void {
    if (event.value == null) return;
    const newMerchant = event.value as BUID;
    this.currentMerchantId = newMerchant.id;
    this.merchantChangeService.setActiveMerchant(event.value);
    this.snackbar.open(`Now viewing: ${newMerchant.name}`, 'Close', {
      duration: 1500,
    });
  }
}
