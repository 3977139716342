import { Component, Inject } from '@angular/core';
import {
  PEBLPaymentLink,
  PEBLPaymentRequest,
} from '../transactions-layout/models/pebl-payment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmationService,
  NotificationService,
  VTService,
} from '@odin/odin-core';

@Component({
  standalone: false,
  selector: 'odin-pebl-tracking-details',
  templateUrl: './pebl-tracking-details.component.html',
  styleUrls: ['./pebl-tracking-details.component.css'],
})
export class PeblTrackingDetailsComponent {
  public actionButtonLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public trackedLink: PEBLPaymentLink,
    public dialogRef: MatDialogRef<PeblTrackingDetailsComponent>,
    private notificationService: NotificationService,
    private vtService: VTService,
    private confirmationService: ConfirmationService,
  ) {}

  public CancelLink(): void {
    this.notificationService.InputDialog(
      {
        placeholder: 'Reason',
        readonly: false,
        title: 'Cancellation Reason',
        subHeading: 'Provide a reason this link is being cancelled',
        defaultValue: '',
        allowCopy: false,
      },
      (reason: string) => {
        if (reason === undefined) {
          this.notificationService.SmallDialog(
            'Link not cancelled',
            'Close',
            3000,
          );
          return;
        }

        this.confirmationService
          .ConfirmDecline(
            'Cancel Link',
            'Are you sure you want to cancel this link?',
            'Confirm',
            'Cancel',
            'primary',
            'warn',
          )
          .subscribe((confirmed: boolean) => {
            if (!confirmed) return;
            this.actionButtonLoading = true;
            this.vtService
              .CancelPaymentLink(this.trackedLink, reason)
              .subscribe(
                () => {
                  this.actionButtonLoading = false;
                  this.dialogRef.close();
                },
                (error: { error: { error: { message: string } } }) => {
                  try {
                    this.notificationService.SmallDialog(
                      `Failed to cancel: ${error.error.error.message}`,
                      'Close',
                      5000,
                    );
                  } catch {
                    this.notificationService.SmallDialog(
                      'An unknown error occured, please try again.',
                      'Close',
                      5000,
                    );
                  }
                  this.actionButtonLoading = false;
                },
              );
          });
      },
      {
        panelClass: ['main-panel'],
        maxWidth: '400px',
        autoFocus: false,
      },
    );
  }

  public ReinstateLink(): void {
    this.confirmationService
      .ConfirmDecline(
        'Renew Link',
        'Are you sure you want to re-create this link? The customer will be notified.',
        'Confirm',
        'Cancel',
        'primary',
        'warn',
      )
      .subscribe((confirmed: boolean) => {
        if (!confirmed) return;
        this.actionButtonLoading = true;
        const request = new PEBLPaymentRequest().copy(this.trackedLink);
        this.vtService.CreatePeblRequest(request).subscribe(
          () => {
            this.actionButtonLoading = false;
            this.dialogRef.close();
          },
          () => {
            this.actionButtonLoading = false;
            this.notificationService.SmallDialog(
              'Failed to reinstate link',
              'Close',
              5000,
            );
          },
        );
      });
  }

  public hasExpired(): boolean {
    const expiration = new Date(this.trackedLink.linkExpiration);
    const now = new Date();
    if (expiration < now) return true;
    if (this.trackedLink.status === 'Cancelled') return true;
    if (this.trackedLink.status === 'Expired') return true;
    return false;
  }

  public deliveryIcon(): string {
    switch (this.trackedLink.delivery) {
      case 'email':
        return 'email';
      case 'sms':
        return 'phone_iphone';
      case 'manual':
        return 'assignment_turned_in';
    }
  }

  public deliveryName(): string {
    switch (this.trackedLink.delivery) {
      case 'email':
        return 'E-mail';
      case 'sms':
        return 'SMS';
      case 'manual':
        return 'Copied';
    }
  }

  public deliveryExpiryIcon(): string {
    if (this.trackedLink.status === 'Authorised') return 'credit_score';
    if (this.trackedLink.status === 'Cancelled') return 'hourglass_disabled';
    const expiration = new Date(this.trackedLink.linkExpiration);
    const now = new Date();
    if (expiration < now) return 'hourglass_disabled';
    if (Math.abs(expiration.getTime() - now.getTime()) / 3600000 < 24)
      return 'hourglass_bottom';
    return 'hourglass_full';
  }

  public deliveryExpiryClass(): string {
    if (this.trackedLink.status == 'Authorised') return 'text-success';
    if (this.trackedLink.status === 'Cancelled') return 'text-danger';

    const expiration = new Date(this.trackedLink.linkExpiration);
    const now = new Date();

    if (expiration < now) return 'text-danger';
    if (Math.abs(expiration.getTime() - now.getTime()) / 3600000 < 24)
      return 'text-warning';
    return 'text-success';
  }

  public statusText(): string {
    const expiration = new Date(this.trackedLink.linkExpiration);
    const now = new Date();

    if (this.trackedLink.status != 'Authorised' && expiration < now)
      return 'Expired';

    switch (this.trackedLink.status) {
      case 'Sent':
        return 'Unpaid';
      case 'Cancelled':
        return 'Cancelled';
      case 'Expired':
        return 'Expired';
      case 'Authorised':
        return 'Paid';
    }
  }

  public copyLink(): void {
    navigator.clipboard.writeText(this.trackedLink.link).then(() => {
      this.notificationService.SmallDialog(
        'Copied to clipboard',
        'Close',
        3000,
      );
    });
  }
}
