import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OdinIntegrationsComponent } from './odin-integrations/odin-integrations.component';
import { OdinIntegrationsRoutingModule } from './odin-integrations/odin-integrations-routing.module';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IntegrationsPageComponent } from './components/integrations-page/integrations-page.component';
import { MatRadioModule } from '@angular/material/radio';
import { IntegrationsXeroPaymentServicesPageComponent } from './components/integrations-xero-paymentservices-page/integrations-xero-paymentservices-page.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { IntegrationsXeroPageComponent } from './components/integrations-xero-page/integrations-xero-page.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OdinCoreModule } from "../../../odin-core/src/lib/odin-core.module";

@NgModule({
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    FontAwesomeModule, 
    OdinCoreModule],
  declarations: [OdinIntegrationsComponent, IntegrationsPageComponent, IntegrationsXeroPageComponent, IntegrationsXeroPaymentServicesPageComponent],
  exports: [OdinIntegrationsComponent, OdinIntegrationsRoutingModule, IntegrationsPageComponent, IntegrationsXeroPageComponent, IntegrationsXeroPaymentServicesPageComponent],
})
export class OdinIntegrationsModule {}
