import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from '@odin/odin-authentication';
import { Setting, SettingsService } from '@odin/odin-settings';
import { MerchantUpdateService } from '@odin/odin-transactions';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment as env } from 'apps/odin-ui/src/environments/environment';
import { BrandingHelper } from './Services/branding-helper';

@Component({
  standalone: false,
  selector: 'odin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public envName: string = env.name;
  public verNumber: string = env.releaseVersion;
  public bhelper = BrandingHelper;

  get themeName(): string {
    if (this.bhelper.currentBranding().fixedTheme) {
      return this.bhelper.currentBranding().themeClass;
    } else {
      const storedTheme = this.settingsService.GetNotNullSetting<string>(
        'themeName',
        'light',
      ).value;
      return storedTheme;
    }
  }

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private settingsService: SettingsService,
    private merchantChangeService: MerchantUpdateService,
  ) {
    router.routeReuseStrategy.shouldReuseRoute = () => false;
    document.body.classList.add(`monek-${this.themeName}-theme`);
    this.settingsService.settingChanged.subscribe((setting: Setting) => {
      if (setting.key == 'themeName') {
        document.body.classList.forEach((c) => {
          if (c.indexOf('-theme') > -1) {
            document.body.classList.remove(c);
          }
        });
        setTimeout(() => {
          document.body.classList.add(`monek-${this.themeName}-theme`);
        }, 50);
      }
    });
  }

  ngOnInit(): void {
    this.cognitoService.refreshToken();
  }
}
