import { Component, Inject } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITransaction } from '../../models';
import {
  Alignment,
  ConfirmationService,
  FormBuilder,
  FormData,
  FormValidation,
  FormField,
  InputIconType,
  InputPrefixSuffixType,
  InputType,
  MonekColour,
  NotificationService,
  Size,
  VTService,
  ValidationPattern,
  OptionInputType,
  FormOption,
  MerchantService,
} from '@odin/odin-core';
import { VTPaymentResponse } from '../../models/vt-payment';
import { CurrencyPipe } from '@angular/common';
import { TransactionRecharge } from '../../models/transaction-recharge';

export class RechargeModalData {
  constructor(public transaction: ITransaction) {}
}

/** Error when invalid control is dirty, touched, or submitted. */
export class AmountErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  standalone: false,
  selector: 'odin-recharge-transaction-modal',
  templateUrl: './recharge-transaction-modal.component.html',
  styleUrls: ['./recharge-transaction-modal.component.scss'],
})
export class RechargeTransactionModalComponent {
  public rechargeForm: FormData<TransactionRecharge> = this.BuildForm();
  public amountMatcher = new AmountErrorStateMatcher();
  public processing: boolean = false;

  constructor(
    private merchantService: MerchantService,
    public dialogRef: MatDialogRef<RechargeTransactionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RechargeModalData,
    private VTService: VTService,
    private notifcationService: NotificationService,
    private confirmationService: ConfirmationService,
    private currencyPipe: CurrencyPipe,
  ) {
    this.rechargeForm = this.BuildForm();
  }

  private BuildForm(): FormData<TransactionRecharge> {
    const userProfile = this.merchantService.getUserprofile();
    const formData = new FormBuilder<TransactionRecharge>(MonekColour.Primary)
      .ToggleSubmitButton(true)
      .AddFormField('OperatorId', '', InputType.Hidden, userProfile?.userId || '', Size.Full)
      .AddFormField('OperatorName', '', InputType.Hidden, userProfile?.email || '', Size.Full)
      .SubmitButtonConfig('Complete', Size.Full, Alignment.Center)
      .AddFormField(
        'PaymentChannel',
        '',
        InputType.Hidden,
        this.data.transaction.channel,
        Size.Auto,
      )
      .AddFormField('AuxiliaryData', '', InputType.Hidden, '', Size.Auto)
      .AddFormField('MerchantID', '', InputType.Hidden, '', Size.Auto)
      .AddFormField('CountryCode', '', InputType.Hidden, '826', Size.Auto)
      .AddFormField('CurrencyCode', '', InputType.Hidden, '826', Size.Auto)
      .AddFormField('ValidityID', '', InputType.Hidden, null, Size.Auto)
      .AddFormField(
        'CrossReference',
        '',
        InputType.Hidden,
        this.data.transaction.crossReference,
        Size.Auto,
      )
      .AddFormField(
        'Amount',
        `Amount`,
        InputType.Number,
        null,
        Size.Full,
        new FormValidation()
          .Required('Amount is required')
          .MinValue(0.01, 'Amount must be greater than £0.01')
          .MaxLength(9)
          .PatternMatch(ValidationPattern.Decimal, 'Amount must be a number'),
        true,
        {
          prefixIcon: new InputPrefixSuffixType(
            InputIconType.ICON,
            'currency_pound',
          ),
        },
        undefined,
        (formfield: FormField, value: string) => {
          if (value !== '') {
            const numericValue = parseFloat(value);
            formfield.value = numericValue.toFixed(2);
            if (!isNaN(numericValue)) {
              formfield.value = numericValue.toFixed(2);
            } else {
              formfield.value = '0.00';
            }
          }
        },
      )
      .AddOptionField(
        'Reason',
        `Reason For Charge`,
        OptionInputType.Dropdown,
        null,
        [
          new FormOption('A', 'Additional Charge'),
          new FormOption('C', 'Unscheduled Charge'),
          new FormOption('D', 'Delayed Charge'),
          new FormOption('L', 'Incremental Charge'),
          new FormOption('X', 'No Show'),
        ],
        Size.Full,
        new FormValidation().Required(),
        true,
        {},
        undefined,
      )
      .Build((data: TransactionRecharge) => {
        this.makeRecharge(data);
      });
    return formData;
  }

  private makeRecharge(data: TransactionRecharge): void {
    this.processing = true;

    this.confirmationService
      .ConfirmDecline(
        'Are you sure to process this recharge?',
        'By selecting “Yes” the recharge will be completed and the original customer will be charged, please select to confirm.',
        'Yes',
        'Cancel',
        'primary',
        'warn',
      )
      .subscribe((resp: boolean) => {
        if (resp) this.executeRecharge(data);
        else this.processing = false;
      });
  }
  private executeRecharge(data: TransactionRecharge): void {
    this.VTService.SubmitRechargeRequest(data).subscribe(
      (_resp: VTPaymentResponse) => {
        if (_resp.status) {
          this.dialogRef.close(null);
          this.notifcationService.SmallDialog(
            'Recharge completed',
            'Close',
            60000,
          );
        } else {
          this.rechargeForm.ShowError(`${_resp.traceId} - ${_resp.message}`);
        }
        this.processing = false;
      },
      () => {
        this.rechargeForm.ShowError('Something went wrong.');
        this.processing = false;
      },
    );
  }

  // close without saving
  public cancel(): void {
    this.dialogRef.close(null);
  }
}
