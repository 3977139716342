import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'odin-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnDestroy {
  searchControl!: FormControl;
  displayClearBtn = false;
  faClear = faTimes;

  @Output() outputValue: EventEmitter<string> = new EventEmitter<string>();
  @Input() debounceTime = 750;
  @Input() inputPlaceholder = '';
  @Input() inputLabel = '';

  inputSearchValue: string = '';
  @Input() set inputValue(value: string) {
    this.inputSearchValue = value;

    this.createFormControl();

    this.displayClearBtn =
      this.inputSearchValue != null && this.inputSearchValue.length > 0;

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        map((next) => next?.trim()), // convert major currency into the minor one
        filter(
          (next: string | undefined) =>
            !next || !next.length || next.trim().length >= 2,
        ),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
      )
      .subscribe((data) => this.outputValue.emit(data));
  }

  protected unsubscribe$ = new Subject<void>();

  private createFormControl() {
    this.searchControl = new FormControl(this.inputSearchValue);
  }

  checkSearchInputValue() {
    let value = this.searchControl.value;

    value = this.trimSearchInputValue(value);

    this.searchControl.patchValue(value);

    if (value.length > 0) this.displayClearBtn = true;
    else this.displayClearBtn = false;
  }

  /* remove left spaces and right spaces except for one */
  /* assuming user types the full name separated by a space */
  private trimSearchInputValue(value: string | null): string {
    if (!value || value === null || value === undefined) return '';

    value = value.trimStart();

    return value.length > 2 &&
      value[value.length - 1] === ' ' &&
      value[value.length - 2] === ' '
      ? value.trimEnd().concat(' ')
      : value;
  }

  clearInput() {
    this.searchControl.reset('');

    this.displayClearBtn = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
