import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTPCONSTANTS, MerchantService } from '@odin/odin-core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import {
  CsvReportLink,
  CsvReportRequest,
  IFormattedTransaction,
  ITransaction,
  TransactionFiltersParams,
} from '../models';

@Injectable()
export class TransactionsService {
  private readonly baseUrl: string = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}`;

  constructor(
    private readonly http: HttpClient,
    private merchantService: MerchantService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMetrics(interval: number): Observable<any> {
    const merchantRef = this.merchantService.getCurrentMerchant();
    if (merchantRef === null) return of();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(
      `${this.baseUrl}/transactions/${merchantRef.id
      }/metrics/interval/${interval.toString()}`,
    );
  }

  getTransaction(crossRef: string): Observable<ITransaction> {
    const merchantRef = this.merchantService.getCurrentMerchant();
    if (merchantRef === null) return of();
    return this.http.get<ITransaction>(
      `${this.baseUrl}/transactions/${merchantRef.id}/transaction/${crossRef}`,
    );
  }

  getTransactions(
    params: TransactionFiltersParams,
    merchantRef: string,
  ): Observable<ITransaction[]> {
    const url = this.constructUrlWithQueryString(merchantRef, params);

    return this.http.get<ITransaction[]>(url);
  }

  findTransaction(merchantRef: string, id: string): Observable<ITransaction> {
    const url = this.constructUrlWithId(merchantRef, id);

    return this.http.get<ITransaction>(url);
  }

  downloadCsvReport(
    params: CsvReportRequest,
    merchantRef: string,
  ): Observable<CsvReportLink> {
    const url = `${this.baseUrl}/transactions/csv/${merchantRef}`;

    return this.http.post<CsvReportLink>(url, params);
  }

  public GetRelatedTransactions(
    transaction: IFormattedTransaction,
  ): Observable<ITransaction[]> {
    return this.http.get<ITransaction[]>(
      `${this.baseUrl
      }/transactions/${this.merchantService.getMerchantRef()}/relation/${transaction.transactionType === 'refund' ||
        transaction.transactionType === 'reversal'
        ? 'parent'
        : 'children'
      }/${transaction.crossReference}`,
    );
  }

  private constructUrlWithQueryString(
    merchantRef: string,
    params: TransactionFiltersParams,
  ): string {
    let url = this.constructUrl(merchantRef, params.query !== '');

    let queryString = `page=${params.page}&take=${params.take}`;

    if (params.query && params.query !== '')
      queryString = `${queryString}&searchExpression=${params.query}`;
    if (params.filters.minDate)
      queryString = `${queryString}&minDate=${moment(
        params.filters.minDate,
      ).format('MM/DD/YYYY')}`;
    if (params.filters.maxDate)
      queryString = `${queryString}&maxDate=${moment(params.filters.maxDate)
        .add(1, 'days')
        .format('MM/DD/YYYY')}`;
    if (params.filters.minAmount)
      queryString = `${queryString}&minAmount=${params.filters.minAmount}`;
    if (params.filters.maxAmount)
      queryString = `${queryString}&maxAmount=${params.filters.maxAmount}`;
    if (params.filters.channel)
      queryString = `${queryString}&channel=${this.formatEnumString(
        params.filters.channel,
      )}`;
    if (params.filters.status)
      queryString = `${queryString}&status=${this.formatEnumString(
        params.filters.status,
      )}`;

    return (url += `?${queryString}`);
  }

  private constructUrlWithId(
    merchantRef: string,
    transactionId: string,
  ): string {
    const url = this.constructUrl(merchantRef);

    return `${url}/details/${transactionId}`;
  }

  private constructUrl(merchantRef: string, hasQuery: boolean = false): string {
    if (hasQuery) return `${this.baseUrl}/transactions/${merchantRef}/search`;
    return `${this.baseUrl}/transactions/${merchantRef}`;
  }

  private formatEnumString(value: string): string {
    return value.replace(/ /g, '_').toLowerCase();
  }
}
