<div class="p-3 page-height overflow-auto">
    <mat-card>
        <mat-card-content *ngIf="!loading()">
            <h2>Monek Payment Services configuration</h2>
            <form (ngSubmit)="submit()">
                <div class="d-flex flex-column">
                    <mat-label class="mb-3">1. Choose invoice branding theme - these can be created and edited on the Xero website</mat-label>
                    <mat-form-field color="primary" appearance="outline">
                        <mat-select [formControl]="themeControl">
                            @for (theme of themes(); track theme) {
                                <mat-option [value]="theme.id">{{theme.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-label class="mb-3">2. Choose Payments account</mat-label>
                    <mat-form-field color="primary" appearance="outline">
                        <mat-select [formControl]="paymentAccountControl">
                            @for (account of paymentAccounts(); track account) {
                                <mat-option [value]="account.id">{{account.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-label class="mb-3">3. Choose Fees account</mat-label>
                    <mat-form-field color="primary" appearance="outline">
                        <mat-select [formControl]="feeAccountControl">
                            @for (account of feeAccounts(); track account) {
                                <mat-option [value]="account.id">{{account.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>  
                </div>
                <button mat-flat-button type="submit" class="me-1 mon-text-primary mon-bg-primary mon-border-primary border">Submit</button>
                <button mat-flat-button class="me-1 border" (click)="cancel()">Cancel</button>
            </form>
        </mat-card-content>
        <odin-loading-spinner class="mt-3 mb-3" [active]="loading()"></odin-loading-spinner>
    </mat-card>
</div>