export class StaticHelpers {
  public static uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }

  public static isNullOrEmpty(str: string | null | undefined | Date): boolean {
    if (str === null) return true;
    if (str === undefined) return true;
    if (str === '') return true;
    return false;
  }

  public static isGuid(value: string): boolean {
    if (StaticHelpers.isNullOrEmpty(value)) return false;
    const guidPattern = new RegExp(
      '^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$',
    );
    return guidPattern.test(value);
  }

  public static isJson(value: string): boolean {
    if (StaticHelpers.isNullOrEmpty(value)) return false;
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isObject(value: any): boolean {
    if (value !== null && typeof value === 'object') return true;
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isArray(value: any): boolean {
    if (Array.isArray(value)) return true;
    return false;
  }

  public static isJsonB64(value: string): boolean {
    try {
      if (StaticHelpers.isNullOrEmpty(value)) return false;
      const decoded = atob(value);
      return StaticHelpers.isJson(decoded);
    } catch (e) {
      return false;
    }
  }

  public static decodeJsonB64(value: string): any {
    try {
      if (StaticHelpers.isNullOrEmpty(value)) return null;
      const decoded = atob(value);
      return JSON.parse(decoded);
    } catch (e) {
      return null;
    }
  }
}
