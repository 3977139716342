import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AdminGuardService,
  AuthGuardService,
  MonekAdminGuardService,
  VirtualTerminalGuardService,
  PEBLGuardService,
} from '@odin/odin-authentication';
import { DashboardComponent } from './dashboard-page/dashboard.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { VirtualTerminalPageComponent } from './virtual-terminal-page/virtual-terminal-page.component';
import { PeblPageComponent } from './pebl-page/pebl-page.component';
import { TransactionOutcomePageComponent } from './transaction-outcome-page/transaction-outcome-page.component';
import { PciFormComponent } from '@odin/odin-core';
import { PeblTrackingPageComponent } from '@odin/odin-transactions';
import { IntegrationsPageComponent, IntegrationsXeroPageComponent, IntegrationsXeroPaymentServicesPageComponent } from '@odin/odin-integrations';

const routes: Routes = [
  // Non auth pages
  { path: '', pathMatch: 'full', redirectTo: '/authentication/login' },
  {
    path: 'transaction-status/:status',
    component: TransactionOutcomePageComponent,
  },

  // Package routing
  {
    path: 'admin',
    loadChildren: () =>
      import('@odin/odin-admin').then((m) => m.OdinAdminModule),
    canActivate: [AuthGuardService, AdminGuardService],
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('@odin/odin-authentication').then(
        (m) => m.OdinAuthenticationModule,
      ),
  },
  {
    path: 'transactions',
    loadChildren: () =>
      import('@odin/odin-transactions').then((m) => m.OdinTransactionsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'integrations',
    loadChildren: () =>
      import('@odin/odin-integrations').then((m) => m.OdinIntegrationsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('@odin/odin-settings').then((m) => m.OdinSettingsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'monek-admin',
    loadChildren: () =>
      import('@odin/odin-monek-admin').then((m) => m.OdinMonekAdminModule),
    canActivate: [AuthGuardService, MonekAdminGuardService],
  },

  // UI pages
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard/pci',
    component: PciFormComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'virtual-terminal/VT',
    component: VirtualTerminalPageComponent,
    canActivate: [AuthGuardService, VirtualTerminalGuardService],
  },
  {
    path: 'virtual-terminal/PEBL',
    component: PeblPageComponent,
    canActivate: [AuthGuardService, PEBLGuardService],
  },
  {
    path: 'virtual-terminal/PEBL-tracking',
    component: PeblTrackingPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'integrations',
    component: IntegrationsPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'integrations/xero',
    component: IntegrationsXeroPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'integrations/xero/:tenantId',
    component: IntegrationsXeroPaymentServicesPageComponent,
    canActivate: [AuthGuardService]
  },
  // Error handling
  { path: '**', component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuardService,
    AdminGuardService,
    MonekAdminGuardService,
    VirtualTerminalGuardService,
    PEBLGuardService,
  ],
})
export class AppRoutingModule {}
