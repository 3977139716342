export class TransactionRefund {
  constructor(
    public amount: number, //Major
    public merchantId: string,
    public countryCode: string = '', // 826
    public currencyCode: string = '', // 826
    public dispatch: string = 'NOW',
    public messageType: string = 'REFUND_CNP',
    public responseAction: string = 'JSON',
    public crossReference: string = '',
    public validityId: string = '',
    public operatorId: string = '',
    public operatorName: string = '',
  ) {}
}
