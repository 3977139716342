import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BUID, HTTPCONSTANTS, NotificationService, UserProfileSettings } from '@odin/odin-core';
import { SettingsService } from '@odin/odin-settings';
import { UserAccessLevel } from '../models/user-access-level';
import { MerchantChangeService, UserProfile } from '@odin/odin-core';
import { SignInSession } from '../models/SignInSession';
import {
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CognitoService {
  // private pool: CognitoUserPool;
  public loginChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  private tokenRefreshTimeout = -1;
  private inactivityTimer = -1;
  private lastInteraction: number = 0;

  constructor(
    @Inject('userPoolId') private _userPoolId: string,
    @Inject('clientId') private _clientId: string,
    @Inject('hostedLoginUrl') private hostedLoginUrl: string,
    @Inject('authCallbackUrl') private authCallbackUrl: string,
    @Inject('congitoResource') private congitoResource: string,
    @Inject('cognitoAudience') private cognitoAudience: string,
    @Inject('sessionInactivityLimit') private sessionInactivityLimit: number,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private settingsService: SettingsService,
    private merchantChangeService: MerchantChangeService,
    private notificationService: NotificationService,
  ) {
    const storedLastTime = localStorage.getItem('lastInteractionTime');
    if (storedLastTime === null) this.lastInteraction = Date.now();
    else this.lastInteraction = Number.parseInt(storedLastTime);

    // add handler to invalidate tokens - future proof for remote trigger
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).invalidateSession = () => {
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken !== null) this.InvalidateToken(refreshToken);
    };

    // add handler to refresh token - future proof for remote trigger
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).refreshToken = () => { this.refreshToken(); }

    this.InactivityCheck();
  }

  public login(): void {
    const scopes: string[] = [
      'email',
      'openid',
      'phone',
      'odin/admin-staff',
      'odin/merchant-staff',
      // 'odin/create-transactions',
      'odin/read-settlements',
      'odin/read-transactions',
      // 'odin/refund-transactions',
    ];
    const scopeString = scopes.join('+');
    const hostedLoginURL = `${this.hostedLoginUrl}/oauth2/authorize?client_id=${this._clientId
      }&response_type=code&scope=${scopeString}&redirect_uri=${encodeURI(
        this.authCallbackUrl,
      )}`;
    this.lastInteraction = Date.now(); // starts inactivity session
    window.location.href = hostedLoginURL;
  }

  public logout(): void {
    this.loginChanged.emit(false);
    const refreshToken = localStorage.getItem('refresh_token');

    localStorage.removeItem('auth');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('profile');

    if (refreshToken !== null) this.InvalidateToken(refreshToken);

    window.location.href = `${this.hostedLoginUrl}/logout?client_id=${this._clientId
      }&logout_uri=${encodeURI(window.location.origin)}`;
  }

  private InvalidateToken(refreshToken: string): void {
    const body = new URLSearchParams();
    body.set('token', refreshToken)
    body.set('client_id', this._clientId)
    this.http.post(`${this.hostedLoginUrl}/oauth2/revoke`, body.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).subscribe(() => { })
  }

  public getAccessToken(): string {
    return localStorage.getItem('token') || '';
  }

  public userLoggedIn(): boolean {
    return this.getAccessToken() !== '' && this.isTokenValid();
  }

  public isAdmin(): boolean {
    const profileJSON = this.settingsService.GetSetting('profile');
    if (profileJSON == null) return false;
    const profile = JSON.parse(profileJSON.value) as UserProfile;
    const adminPageRoles = [
      UserAccessLevel.Admin,
      UserAccessLevel.Reseller,
      UserAccessLevel.MerchantAdmin,
    ];
    return adminPageRoles.indexOf(profile.accessLevel) > -1;
  }

  public isMonekAdmin(): boolean {
    const profileJSON = this.settingsService.GetSetting('profile');
    if (profileJSON == null) return false;
    const profile = JSON.parse(profileJSON.value) as UserProfile;
    const adminPageRoles = [UserAccessLevel.Admin];
    return adminPageRoles.indexOf(profile.accessLevel) > -1;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseJWT(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  public CompleteLogin(): void {
    this.lastInteraction = Date.now(); // starts inactivity session
    this.fetchProfile();
    this.loginChanged.emit(true);
    this.InactivityCheck();
  }

  public fetchProfileSettings(userId: string): Observable<UserProfileSettings> {
    const url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}/identity-management/users/${userId}/profile/settings`;
    return this.http
      .get<UserProfileSettings>(url, {
        headers: {
          DONTRETRY: 'true',
        },
      });
  }

  public updateProfileSettings(userId: string, settings: UserProfileSettings): Observable<UserProfileSettings> {
    const url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}/identity-management/users/${userId}/profile/settings/update`;
    return this.http.post<UserProfileSettings>(url, settings);
  }

  public fetchProfile(): void {
    const tkn = localStorage.getItem('token');
    if (tkn == null) return; // kickout?
    const jwt = this.parseJWT(tkn) as {
      sub: string;
    };
    this.getUserProfile(jwt.sub, (profile: UserProfile | null) => {
      if (profile === null) {
        this.snackbar.open(
          'Failed to fetch user profile. Please contact support.',
          'Close',
        );
      } else {
        this.settingsService.SetSetting('profile', JSON.stringify(profile));
        this.loginChanged.emit(true);
      }
    });
  }

  public userProfile(): UserProfile | null {
    const profileSetting = this.settingsService.GetSetting('profile');
    if (profileSetting === null) return null;
    const profileJSON = profileSetting.value;
    return JSON.parse(profileJSON) as UserProfile;
  }

  public getUserAccessLevel(): UserAccessLevel | null {
    const profile = this.userProfile();
    if (profile === null) return null;
    return profile.accessLevel;
  }

  public getUserProfile(
    userId: string,
    callback: (userProfile: UserProfile | null) => void,
    filterBuids: boolean = true,
  ): void {
    const url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.odinBaseAddress}/identity-management/users/${userId}/profile`;
    this.http
      .get<UserProfile>(url, {
        headers: {
          DONTRETRY: 'true',
        },
      })
      .subscribe(
        (profile: UserProfile) => {
          // buid:View
          // map visible buids
          const hasFilteredBuids =
            profile.grants.filter((g) => g.action === 'buid:view').length > 0;
          if (hasFilteredBuids && filterBuids) {
            profile.buids = profile.buids.filter((buid) => {
              return (
                profile.grants.filter((g) => {
                  return g.action === 'buid:view' && g.resource === buid.id;
                }).length > 0
              );
            });
          }
          // end map

          callback(profile);
        },
        () => {
          this.snackbar.open(
            'Failed to fetch user profile. Please contact support.',
            'Close',
          );
          callback(null);
        },
      );
  }

  private getUserBuids(
    userId: string,
    callback: (buids: BUID[] | null) => void,
  ): void {
    this.getUserProfile(userId, (profile: UserProfile | null) => {
      callback(profile == null ? null : profile.buids);
    });
  }

  private associateToken(
    res: SignInSession,
    callback: (success: boolean) => void,
  ): void {
    if (res === null || res === undefined) return;
    const accessToken = this.parseJWT(res.access_token) as {
      sub: string;
      exp: number;
    };

    localStorage.setItem('auth', JSON.stringify(res));
    localStorage.setItem('token', res.access_token);
    localStorage.setItem('refresh_token', res.refresh_token);
    localStorage.setItem('exp', accessToken.exp.toString());

    setTimeout(() => {
      this.getUserBuids(accessToken.sub, (userBuids: BUID[] | null) => {
        if (userBuids === null) {
          callback(false);
          this.loginChanged.emit(false);
          return;
        }

        if (userBuids.length > 0) {
          localStorage.setItem('merchant-ref', JSON.stringify(userBuids));

          const currentMid = localStorage.getItem('current-merchant-ref');
          if (currentMid == null) {
            const init_mid = userBuids[0];
            this.merchantChangeService.setActiveMerchant(init_mid);
          } else {
            const parsedMid = JSON.parse(currentMid) as BUID;
            const midInProfile =
              userBuids.filter((b) => b.id === parsedMid.id).length > 0;
            if (!midInProfile) {
              const init_mid = userBuids[0];
              this.merchantChangeService.setActiveMerchant(init_mid);
            } else {
              this.merchantChangeService.setActiveMerchant(parsedMid); // re-assign mid to ensure updated
            }
          }
        } else {
          localStorage.removeItem('current-merchant-ref');
          localStorage.setItem('merchant-ref', JSON.stringify(userBuids));
          this.snackbar.open('No locations for user.', 'Close');
        }

        callback(true);
      });
    }, 1000);
  }

  public isTokenValid(): boolean {
    const tokenExp = Number.parseInt(localStorage.getItem('exp') || '0');
    const now = Date.now() / 1000; // convert to seconds
    return now < tokenExp;
  }

  public InactivityCheck(): void {
    if (this.userLoggedIn()) {
      this.ResetInactivityTimer();
      document.onmousemove = () => this.ResetInactivityTimer();
      document.onkeyup = () => this.ResetInactivityTimer();
      window.addEventListener('beforeunload', () => {
        localStorage.setItem(
          'lastInteractionTime',
          this.lastInteraction.toString(),
        );
      });
    }
  }

  private ResetInactivityTimer() {
    if (this.userLoggedIn()) {
      localStorage.setItem(
        'lastInteractionTime',
        this.lastInteraction.toString(),
      );
      if (this.lastInteraction + this.sessionInactivityLimit < Date.now()) {
        this.logout();
        return;
      }
      this.lastInteraction = Date.now();
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = window.setTimeout(() => {
        this.logout();
      }, this.sessionInactivityLimit);
    }
  }

  public checkRefresh(): void {
    const tokenExp = Number.parseInt(localStorage.getItem('exp') || '0');
    const now = Date.now() / 1000; // convert to seconds
    const timeTillExpiry = tokenExp - now;
    const threshold = 30;
    const timeTillRefresh = timeTillExpiry - threshold;
    if (this.tokenRefreshTimeout > -1) clearTimeout(this.tokenRefreshTimeout);
    this.tokenRefreshTimeout = window.setTimeout(() => {
      this.refreshToken();
    }, timeTillRefresh * 1000);
  }

  public refreshToken(): void {
    if (!this.userLoggedIn()) return;
    const tokenString = localStorage.getItem('refresh_token');
    if (tokenString === null) return;
    const token = new CognitoRefreshToken({
      RefreshToken: tokenString,
    });
    const user = this.getCognitoUser();
    if (user === null) return;
    user.refreshSession(token, (err: string, session: CognitoUserSession) => {
      if (err) {
        localStorage.removeItem('token');
        this.loginChanged.emit(false);
        return;
      }
      this.associateToken(
        new SignInSession().FromCognito(session),
        (success: boolean) => {
          if (!success) {
            this.loginChanged.emit(false);
          }
          if (success) {
            this.fetchProfile();
            this.checkRefresh();
          }
        },
      );
    });
  }

  private getCognitoUser(): CognitoUser | null {
    const userProfile = this.userProfile();
    if (userProfile === null) return null;
    const poolData = {
      UserPoolId: this._userPoolId,
      ClientId: this._clientId,
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: userProfile.email,
      Pool: userPool,
    };
    return new CognitoUser(userData);
  }

  public getTokenFromCode(
    code: string,
    callback: (success: boolean) => void,
  ): void {
    const url = `${this.hostedLoginUrl}/token`;

    const body = new URLSearchParams();
    const lastCode = localStorage.getItem('last_code');
    if (lastCode == code) {
      callback(true);
      return;
    } // dont retry this code
    localStorage.setItem('last_code', code);
    body.set('grant_type', 'authorization_code');
    body.set('code', code);
    body.set('redirect_uri', this.authCallbackUrl);
    body.set('resource', this.congitoResource);
    body.set('audience', this.cognitoAudience);
    body.set('client_id', this._clientId);

    this.http
      .post<SignInSession>(url, body.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .subscribe(
        (res: SignInSession) => {
          this.associateToken(res, callback);
        },
        (err: HttpErrorResponse) => {
          localStorage.removeItem('organisationId');
          localStorage.removeItem('auth');
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('current-merchant-ref');
          this.notificationService.SmallDialog(
            'There was an error logging in. (ODN-1000)',
          );
          callback(false);
          this.loginChanged.emit(false);
        },
      );
  }
}
