<div class="p-3">
  <mat-card appearance="outlined" class="main-panel p-2">
    <mat-card-title>Notifications and Alerts</mat-card-title>
    <mat-card-subtitle
      >Any important information for your account will show
      here</mat-card-subtitle
    >
    <mat-card-content>
      <ng-container *ngIf="messages && messages.length > 0; else noMessages">
        <ng-container *ngFor="let message of messages; let i = index">
          <mat-divider></mat-divider>
          <div class="announcement-item">
            <div class="icon {{ message.type }}">
              <fa-icon [icon]="message.icon" size="2x"></fa-icon>
            </div>
            <div class="body">
              <div class="header">
                <h2>{{ message.type }}</h2>
                <h3>{{ message.date | relativeTime }}</h3>
              </div>
              <p class="content" [innerHtml]="message.message"></p>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noMessages>
        <h3 data-test="announcement-bottom" class="announcement-bottom">
          There are currently no messages or alerts.
        </h3>
      </ng-template>
    </mat-card-content>
    <mat-card-actions align="end">
      <!-- <button *ngIf="messages && messages.length > 0" mat-button disabled color="primary">Mark all as read</button> -->
    </mat-card-actions>
  </mat-card>
</div>
