import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HTTPCONSTANTS, MerchantService } from "@odin/odin-core";
import { Observable } from "rxjs";
import { Account, AccountFilter, Binding, BindingSetupBody, BrandingTheme, ConnectionsResponse, PaymentServiceSetupForm, Tenant } from "../models/xero";

@Injectable({providedIn: 'root'})
export class XeroIntegrationsService {
  constructor(
    private readonly http: HttpClient,
    private readonly merchantService: MerchantService
  ) {}

  getLoginUrl(): Observable<string> {
    return this.http.get<string>(`${HTTPCONSTANTS.xeroProtocol}${HTTPCONSTANTS.xeroBaseAddress}/binding/login`);
  }

  getBindings(): Observable<Binding[]> {
    const buid = this.merchantService.getMerchantRef();

    return this.http.get<Binding[]>(`${HTTPCONSTANTS.xeroProtocol}${HTTPCONSTANTS.xeroBaseAddress}/binding/${buid}`);
  }

  getConnections(code: string): Observable<ConnectionsResponse> {
    const buid = this.merchantService.getMerchantRef();

    return this.http.get<ConnectionsResponse>(`${HTTPCONSTANTS.xeroProtocol}${HTTPCONSTANTS.xeroBaseAddress}/binding/connections/${buid}?code=${code}`);
  }

  getBrandingThemes(tenantId: string): Observable<BrandingTheme[]> {
    const buid = this.merchantService.getMerchantRef();

    return this.http.get<BrandingTheme[]>(`${HTTPCONSTANTS.xeroProtocol}${HTTPCONSTANTS.xeroBaseAddress}/paymentservices/branding/${buid}/${tenantId}`);
  }

  getAccounts(tenantId: string, filter: AccountFilter): Observable<Account[]> {
    const buid = this.merchantService.getMerchantRef();

    return this.http.get<Account[]>(`${HTTPCONSTANTS.xeroProtocol}${HTTPCONSTANTS.xeroBaseAddress}/paymentservices/accounts/${buid}/${tenantId}/${filter}`);
  }

  createBinding(body: BindingSetupBody): Observable<object> {
    const buid = this.merchantService.getMerchantRef();
    body.buid = buid;

    return this.http.post(`${HTTPCONSTANTS.xeroProtocol}${HTTPCONSTANTS.xeroBaseAddress}/binding`, body);
  }

  createPaymentService(body: PaymentServiceSetupForm): Observable<object> {
    const buid = this.merchantService.getMerchantRef();
    body.buid = buid;

    return this.http.post(`${HTTPCONSTANTS.xeroProtocol}${HTTPCONSTANTS.xeroBaseAddress}/paymentservices`, body);
  }

  deleteBinding(tenantId: string): Observable<object> {
    const buid = this.merchantService.getMerchantRef();

    return this.http.delete(`${HTTPCONSTANTS.xeroProtocol}${HTTPCONSTANTS.xeroBaseAddress}/binding/${buid}/${tenantId}`);
  }

  deleteAllBindings(): Observable<object> {
    const buid = this.merchantService.getMerchantRef();

    return this.http.delete(`${HTTPCONSTANTS.xeroProtocol}${HTTPCONSTANTS.xeroBaseAddress}/binding/${buid}`);
  }
}