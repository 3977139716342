import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmationModalDetail {
  constructor(
    public title: string,
    public body: string,
    public confirmText: string,
    public declineText: string,
    public confirmColour: 'primary' | 'accent' | 'warn',
    public declineColour: 'primary' | 'accent' | 'warn'
  ) {}
}

@Component({
  standalone: false,
  selector: 'odin-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationModalDetail
  ) {}

  public decline(): void {
    this.dialogRef.close(false);
  }
  public confirm(): void {
    this.dialogRef.close(true);
  }
}
