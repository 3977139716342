import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  faAngleRight,
  faBug,
  faCashRegister,
  faCircleQuestion,
  faClipboardCheck,
  faClockRotateLeft,
  faGlobe,
  faHeadset,
  faLink,
  faMoneyBillTransfer,
  faRepeat,
  faRotateLeft,
  faShop,
  faStore,
  faStoreAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  IFormattedTransaction,
  ITransaction,
  ScrollDirection,
  TransactionType,
} from '../../models';
import { MatDialog } from '@angular/material/dialog';
import { TransactionFormatterService } from '../../services/transaction-formatter.service';
import { PaginationHelperService } from 'libs/odin-core/src/lib/services/pagination-helper.service';
import { CardHelperService } from 'libs/odin-core/src/lib/services/card-helper.service';
import {
  TransactionDetailModalData,
  TransactionDetailsComponent,
} from '../transaction-details/transaction-details.component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Router } from '@angular/router';

@Component({
  standalone: false,
  selector: 'odin-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsListComponent implements AfterViewInit {
  public rowSize = 62;
  @ViewChild(CdkVirtualScrollViewport)
  virtualScroll: CdkVirtualScrollViewport | null = null;

  @Input() set transactions(value: ITransaction[]) {
    this.formattedTransactions = value
      .map((transaction: ITransaction) => {
        const formattedtransaction =
          this.transactionFormatterService.formatTransaction(transaction);
        return formattedtransaction;
      })
      .filter((t) => !t.isReversal);
  }

  scrollDisable = false;
  @Input() set loading(value: boolean | null) {
    this.scrollDisable = value || false;
  }

  @Input() allowSelection: boolean | null = true;
  @Input() hasNext: boolean | null = false;
  @Input() hasPrev: boolean | null = false;
  @Output() scrollDirection: EventEmitter<ScrollDirection> =
    new EventEmitter<ScrollDirection>();
  @Input() refresh: EventEmitter<void> = new EventEmitter<void>();

  faAngleRight = faAngleRight;
  formattedTransactions: IFormattedTransaction[] = [];

  infiniteScrollDistance = 1.4;
  infiniteScrollThrottle = 150;
  fromRoot = true;

  constructor(
    private router: Router,
    private transactionFormatterService: TransactionFormatterService,
    private paginationHelper: PaginationHelperService,
    public cardService: CardHelperService,
    private dialog: MatDialog,
  ) {}

  public ngAfterViewInit(): void {
    if (this.virtualScroll == null) return;
    this.virtualScroll.elementScrolled().subscribe(() => {
      if (this === null || this.virtualScroll === null) return;
      const distFromBottom = this.virtualScroll.measureScrollOffset('bottom');
      this.paginationHelper.detectEndOfScrollRaw(
        distFromBottom,
        this.rowSize,
        15,
        () => {
          if (this.hasNext) this.scrollDirection.emit(ScrollDirection.Down);
        },
      );
    });
  }

  select(transaction: ITransaction): void {
    if (!this.allowSelection) return;

    this.router.navigate([
      `/transactions/detail/${transaction.crossReference}`,
    ]);

    // this.dialog.open(TransactionDetailsComponent, {
    //   data: {
    //     data: new TransactionDetailModalData(transaction, [
    //       transaction.crossReference,
    //     ]),
    //     refreshTrigger: this.refresh,
    //   },
    //   panelClass: ['main-panel', 'large-modal'],
    // });
  }

  transactionIcon(transaction: ITransaction): IconProp {
    if (transaction.transactionType == 'virtual_terminal') return faHeadset;
    if (transaction.transactionType == 'pay_by_link') return faLink;
    if (transaction.transactionType == 'ecommerce') return faGlobe;
    if (transaction.transactionType == 'refund') return faRotateLeft;
    if (transaction.channel == 'recurring') return faRepeat;
    if (transaction.channel == 'ecommerce') return faGlobe;
    if (transaction.channel == 'moto') return faHeadset;
    if (transaction.channel == 'cardholder_present') return faStore;
    return faCircleQuestion;
  }
}
