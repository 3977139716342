import { BUID } from './BUID';
import { Md5 } from 'ts-md5';

export interface UserProfileSettings {
  phoneNumber: string;
  mfaMethod: 'SMS_MFA' | 'EMAIL_OTP';
}

export interface UserProfile {
  buids: BUID[];
  grants: { action: string; resource: string }[];
  organisationId: string;
  email: string;
  name: string;
  accessLevel: number;
  accessLevelText: string;
  userId: string;
  createdAt: Date;
  lastUpdated: Date;
  deletedAt: Date;
  enabled: boolean;
}

export class ExtendedUserProfile implements UserProfile {
  buids: BUID[];
  grants: { action: string; resource: string }[];
  organisationId: string;
  email: string;
  name: string;
  accessLevel: number;
  accessLevelText: string;
  userId: string;
  picture: string;
  createdAt: Date;
  lastUpdated: Date;
  deletedAt: Date;
  enabled: boolean;

  public Build(profile: UserProfile): ExtendedUserProfile {
    this.buids = profile.buids;
    this.grants = profile.grants;
    this.organisationId = profile.organisationId;
    this.email = profile.email;
    this.name = profile.name;
    this.accessLevel = profile.accessLevel;
    this.accessLevelText = profile.accessLevelText;
    this.userId = profile.userId;
    this.createdAt = profile.createdAt;
    this.lastUpdated = profile.lastUpdated;
    this.deletedAt = profile.deletedAt;
    this.enabled = profile.enabled;

    const address = String(this.email).trim().toLowerCase();
    const hash = Md5.hashStr(address);
    this.picture = `https://www.gravatar.com/avatar/${hash}?s=200&d=identicon&r=g`;
    return this;
  }


  // returns if the grant is active or not after toggle
  public ToggleResource(buid: BUID, grant: string): boolean {
    const existingRecord = this.grants.findIndex(g => g.resource === buid.id && g.action === grant);
    if (existingRecord > -1) {
      this.grants.splice(existingRecord);
      return false;
    } else {
      this.grants.push({ action: grant, resource: buid.id });
      return true;
    }
  }

  public HasResource(buid: BUID, grant: string): boolean {
    return this.grants.findIndex(g => g.resource === buid.id && g.action === grant) > -1
  }
}
