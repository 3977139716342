export enum TransactionType {
  Sale = 'sale',
  Refund = 'refund',
  Verification = 'verification',
  Reversal = 'reversal',
  Sale_With_Cashback = 'sale_with_cashback',
  VirtualTerminal = 'virtual_terminal',
  PEBL = 'pay_by_link',
  Unknown = 'unknown',
  ECOMMERCE = 'ecommerce',
  MOTO = 'moto',
}
