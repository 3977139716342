import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputDialogConfig } from '../models/input-dialog-config';

@Component({
  standalone: false,
  selector: 'odin-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css'],
})
export class InputDialogComponent {
  public value: string;
  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: InputDialogConfig,
  ) {
    this.value = config.defaultValue;
  }

  public Close(): void {
    this.dialogRef.close(this.value);
  }

  public copyText(): void {
    navigator.clipboard.writeText(this.value);
  }
}
