import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatchMediaService,
  PaginationHelperService,
  VTService,
} from '@odin/odin-core';
import { PEBLPaymentLink, PaymentLinkList } from '../models/pebl-payment';
import { MatDialog } from '@angular/material/dialog';
import { PeblTrackingDetailsComponent } from '../../pebl-tracking-details/pebl-tracking-details.component';
import { MerchantChangeService } from '@odin/odin-core';
import { AccessGuardService } from '@odin/odin-authentication';
import { Subscription } from 'rxjs';
import * as GLOBALS from '../transactions-global.const.values';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  standalone: false,
  selector: 'odin-pebl-tracking-page',
  templateUrl: './pebl-tracking-page.component.html',
  styleUrls: ['./pebl-tracking-page.component.scss'],
})
export class PeblTrackingPageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public rowSize = 62;
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  virtualScroll: CdkVirtualScrollViewport | null = null;
  public trackingLinks: PEBLPaymentLink[] = [];
  public paginationToken: string = '';
  public loading: boolean = false;
  public fetched: boolean = false;
  public moreContent: boolean = true;
  public peblFilters: {
    startDate?: Date;
    endDate?: Date;
    status?: 'Sent' | 'Cancelled' | 'Expired' | 'Authorised';
  } = {
    startDate: undefined,
    endDate: undefined,
    status: undefined,
  };

  private merchantChangeSub: Subscription;

  tabletQuery!: MediaQueryList;
  mobileQuery!: MediaQueryList;
  isTouch = false;

  constructor(
    private accessService: AccessGuardService,
    private vtService: VTService,
    private paginationHelper: PaginationHelperService,
    private dialog: MatDialog,
    private merchantChangeService: MerchantChangeService,
    private mediaService: MatchMediaService,
  ) {
    this.isTouch = this.mediaService.isTouch();
    this.tabletQuery = this.mediaService.matchMedia(GLOBALS.MAX_SCREEN_WIDTH);
    this.mobileQuery = this.mediaService.matchMedia(GLOBALS.MIN_SCREEN_WIDTH);

    this.merchantChangeSub =
      this.merchantChangeService.merchantChangeEvent.subscribe(() => {
        this.RefreshLinks();
        if (!this.GrantChecker())
          this.accessService.boot(
            'User does not have access to Pay By Link Tracking. Please contact an administrator',
          );
      });
  }

  public GrantChecker(): boolean {
    return this.accessService.hasGrant('txn:CreatePebl');
  }

  public ngOnInit(): void {
    this.FetchPebl(this.paginationToken);
  }

  public startDateChange(e: MatDatepickerInputEvent<Date>): void {
    if (e.value == undefined) this.peblFilters.startDate = undefined;
    else {
      this.peblFilters.startDate = new Date(e.value);
      this.peblFilters.startDate.setHours(0);
      this.peblFilters.startDate.setMinutes(0);
      this.peblFilters.startDate.setSeconds(0);
    }
    this.filterChange();
  }

  public endDateChange(e: MatDatepickerInputEvent<Date>): void {
    if (e.value == undefined) this.peblFilters.endDate = undefined;
    else {
      this.peblFilters.endDate = new Date(e.value);
      // set to end of today
      this.peblFilters.endDate.setHours(23);
      this.peblFilters.endDate.setMinutes(59);
      this.peblFilters.endDate.setSeconds(59);
    }
    this.filterChange();
  }

  public clearFilters(): void {
    this.peblFilters.endDate = undefined;
    this.peblFilters.startDate = undefined;
    this.peblFilters.status = undefined;
    this.filterChange();
  }

  public filterChange(): void {
    this.RefreshLinks();
  }

  public ngAfterViewInit(): void {
    this.ConfigurePagination();
  }

  ngOnDestroy(): void {
    if (this.merchantChangeSub !== undefined)
      this.merchantChangeSub.unsubscribe();
  }

  private ConfigurePagination(): void {
    if (!this.virtualScroll) {
      return;
    }
    this.virtualScroll.elementScrolled().subscribe(() => {
      if (!this || !this.virtualScroll || this.loading) return;

      const distFromBottom = this.virtualScroll.measureScrollOffset('bottom');
      this.paginationHelper.detectEndOfScrollRaw(
        distFromBottom,
        this.rowSize,
        5,
        () => {
          if (
            this.paginationToken === undefined ||
            this.paginationToken === '' ||
            this.loading
          ) {
            return;
          }
          this.FetchPebl(this.paginationToken);
        },
      );
    });
  }

  private FetchPebl(paginationToken?: string): void {
    if (this.loading) return;
    this.loading = true;
    this.vtService.GetPeblLinks(this.peblFilters, paginationToken).subscribe(
      (_: PaymentLinkList) => {
        this.trackingLinks = [...this.trackingLinks, ..._.paymentRequests];
        this.paginationToken = _.lastEvaluatedKey;
        if (this.paginationToken === '') {
          this.moreContent = false;
        }
        setTimeout(() => {
          this.loading = false;
          this.fetched = true;
        }, 500);
      },
      () => {
        this.fetched = true;
        this.loading = false;
      },
    );
  }

  public RefreshLinks(): void {
    this.trackingLinks = [];
    this.paginationToken = '';
    this.moreContent = true;
    this.fetched = false;
    this.loading = false;
    this.FetchPebl();
  }

  public OpenDetails(trackedLink: PEBLPaymentLink): void {
    this.dialog
      .open(PeblTrackingDetailsComponent, {
        data: trackedLink,
        panelClass: ['main-panel', 'large-modal'],
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.RefreshLinks();
      });
  }

  public hasExpired(row: PEBLPaymentLink): boolean {
    const expiration = new Date(row.linkExpiration);
    const now = new Date();
    if (expiration < now) return true;
    if (row.status === 'Cancelled') return true;
    if (row.status === 'Expired') return true;
    return false;
  }

  public rowDeliveryIcon(row: PEBLPaymentLink): string {
    switch (row.delivery) {
      case 'email':
        return 'email';
      case 'sms':
        return 'phone_iphone';
      case 'manual':
        return 'assignment_turned_in';
    }
  }

  public rowDeliveryTooltip(row: PEBLPaymentLink): string {
    switch (row.delivery) {
      case 'email':
        return 'E-mail';
      case 'sms':
        return 'SMS';
      case 'manual':
        return 'Copied';
    }
  }

  public rowDeliveryExpiryIcon(row: PEBLPaymentLink): string {
    if (row.status === 'Cancelled') return 'hourglass_disabled';
    const expiration = new Date(row.linkExpiration);
    const now = new Date();
    if (expiration < now) return 'hourglass_disabled';
    if (Math.abs(expiration.getTime() - now.getTime()) / 3600000 < 24)
      return 'hourglass_bottom';
    return 'hourglass_full';
  }

  public rowDeliveryExpiryClass(row: PEBLPaymentLink): string {
    if (row.status == 'Authorised') return 'text-success';
    if (row.status === 'Cancelled') return 'text-danger';

    const expiration = new Date(row.linkExpiration);
    const now = new Date();

    if (expiration < now) return 'text-danger';
    if (Math.abs(expiration.getTime() - now.getTime()) / 3600000 < 24)
      return 'text-warning';
    return 'text-success';
  }

  public rowDeliveryPillClass(
    row: PEBLPaymentLink,
    withForeground: boolean,
  ): string {
    const expiration = new Date(row.linkExpiration);
    const now = new Date();

    if (row.status != 'Authorised' && expiration < now)
      return `${withForeground ? 'text-white' : 'text-white'} bg-danger`;

    switch (row.status) {
      case 'Sent':
        return `${withForeground ? 'text-black' : 'text-white'} bg-warning`;
      case 'Cancelled':
        return `${withForeground ? 'text-white' : 'text-white'} bg-secondary`;
      case 'Expired':
        return `${withForeground ? 'text-white' : 'text-white'} bg-danger`;
      case 'Authorised':
        return `${withForeground ? 'text-white' : 'text-white'} bg-success`;
    }
  }

  public rowDeliveryPillText(row: PEBLPaymentLink): string {
    const expiration = new Date(row.linkExpiration);
    const now = new Date();

    if (row.status != 'Authorised' && expiration < now) return 'Expired';

    switch (row.status) {
      case 'Sent':
        return 'Unpaid';
      case 'Cancelled':
        return 'Cancelled';
      case 'Expired':
        return 'Expired';
      case 'Authorised':
        return 'Paid';
    }
  }

  public rowDeliveryPillIcon(row: PEBLPaymentLink): string {
    const expiration = new Date(row.linkExpiration);
    const now = new Date();

    if (row.status != 'Authorised' && expiration < now) return 'cancel';

    switch (row.status) {
      case 'Sent':
        return 'watch_later';
      case 'Cancelled':
        return 'cancel';
      case 'Expired':
        return 'cancel';
      case 'Authorised':
        return 'check_circle';
    }
  }
}
