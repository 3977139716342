import { EventEmitter } from '@angular/core';
import { FormValidation } from './form-validation';
import { InputType, OptionInputType } from './input-type.enum';
import { Size } from './size.enum';
import { FormOption } from './form-option';

export enum FormFieldType {
  Section = 'SECTION',
  Detail = 'DETAIL',
  Field = 'FIELD',
  Option = 'OPTION',
}

export class FormField {
  public valueChanged: EventEmitter<string | number | boolean | Date | null> =
    new EventEmitter<string | number | boolean | Date | null>();

  // Section
  public sectionName = '';

  // Field
  public optionInput = false;
  public id = '';
  public value: string | number | boolean | Date | null = null;
  public viewName = '';
  public type: InputType | OptionInputType = InputType.Hidden;
  public options: FormOption[] = [];
  public size: Size = Size.Full;
  public validation: FormValidation = new FormValidation();
  public ownLine = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public data: any = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onChange: (
    value: string | number | boolean | Date | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataCallback: (data: any) => void,
    valCallback: (val: string | number | boolean | Date | null) => void,
  ) => void =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
    (
      value: string | number | boolean | Date | null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataCallback: (data: any) => void,
    ) => {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onBlur: (formField: FormField, value: any) => void = () => {};

  constructor(public formFieldType: FormFieldType) {}

  public Build(
    id: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: string | number | boolean | Date | null,
    viewName: string,
    type: InputType,
    size: Size = Size.Full,
    validation: FormValidation = new FormValidation(),
    ownLine: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (
      value: string | number | boolean | Date | null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataCallback: (data: any) => void,
      valCallback: (val: string | number | boolean | Date | null) => void,
    ) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBlur: (formField: FormField, value: any) => void = () => {},
  ): FormField {
    this.id = id;
    this.value = value;
    this.viewName = viewName;
    this.type = type;
    this.size = size;
    this.validation = validation;
    this.ownLine = ownLine;
    this.data = data;
    this.onChange = onChange;
    this.onBlur = onBlur;
    return this;
  }

  public BuildOption(
    id: string,
    value: string | number | boolean | null,
    viewName: string,
    type: OptionInputType,
    options: FormOption[],
    size: Size = Size.Full,
    validation: FormValidation = new FormValidation(),
    ownLine: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any = {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (
      value: string | number | boolean | Date | null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataCallback: (data: any) => void,
      valCallback: (val: string | number | boolean | Date | null) => void,
    ) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBlur: (formField: FormField, value: any) => void = () => {},
  ): FormField {
    this.optionInput = true;
    this.id = id;
    this.value = value;
    this.viewName = viewName;
    this.type = type;
    this.options = options.map((x: FormOption) => {
      if (x.value != null) x.value = x.value.toString();
      return x;
    });
    this.size = size;
    this.validation = validation;
    this.ownLine = ownLine;
    this.data = data;
    this.onChange = onChange;
    this.onBlur = onBlur;
    return this;
  }

  public SetValue(value: string | number | boolean | Date | null): void {
    this.value = value;
    this.valueChanged.emit(value);
  }
}
