import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { SettlementsFacade } from '../../../+state/settlements/settlements.facade';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  standalone: false,
  selector: 'odin-settlements-monthly-list',
  templateUrl: './settlements-monthly-list.component.html',
  styleUrls: ['./settlements-monthly-list.component.scss'],
})
export class SettlementsMonthlyListComponent implements OnInit{
  public rowSize = 56;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport | null = null;

  @Output() newMonthlySettlement = new EventEmitter();
  @Output() newMonthlySettlementCSV = new EventEmitter();

  monthlySettlementIcon: IconDefinition = faCalendar;
  monthlySettlementDownloadIcon: IconDefinition = faDownload;

  listOfMonths: Date[] = [];
  selectedMonth: Date = new Date();

  loading$!: Observable<boolean>;

  constructor(private facade: SettlementsFacade) {}

    ngOnInit() {
      this.listOfMonths = this.getMonthsInRange(this.getSettlementStartDate(), this.getPreviousMonth())
      this.loading$ = this.facade.getMonthlySettlementReportsLoader$;
    }

    selectedDate(selectedDateValue: Date) {
      this.selectedMonth = selectedDateValue;
      this.newMonthlySettlement.emit(selectedDateValue);
    }

    selectedDateCSV(selectedDateValue: Date) {
      this.selectedMonth = selectedDateValue;
      this.newMonthlySettlementCSV.emit(selectedDateValue);
    }

    getPreviousMonth(): Date {
      const now = new Date();
      const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 2);
      return previousMonth;
    }

    getSettlementStartDate(): Date {
      const settlementStartDate = new Date('2023-02-02T00:00:00');
      return settlementStartDate;
    }

    getMonthsInRange(startDate: Date, endDate: Date): Date[] {
      const months: Date[] = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        months.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(2);
      }
      return months.reverse();
    }
  }
