import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo } from 'rxjs/operators';
import {
  MICRO_OFFSET,
  PULLED_DISTANCE,
  PULLING,
  PULL_TO_REFRESH_PROVIDERS,
} from './pull-to-refresh.providers';

const IOS_LOADING_DISTANCE = PULLED_DISTANCE / 2;

function translateY(distance: number): string {
  return `translateY(${distance}px)`;
}

@Component({
  standalone: false,
  selector: 'odin-pull-to-refresh',
  templateUrl: './pull-to-refresh.component.html',
  styleUrls: ['./pull-to-refresh.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PULL_TO_REFRESH_PROVIDERS],
})
export class PullToRefreshComponent {
  @Input() isLoading: boolean | null = false;

  @Output()
  readonly pulled: Observable<void> = this.pulling$.pipe(
    distinctUntilChanged(),
    filter(
      (distance) => distance === PULLED_DISTANCE && this.isLoading === false,
    ),
    mapTo(undefined),
  );

  constructor(
    @Inject(PULLING) private readonly pulling$: Observable<number>,
  ) {}

  public isIOS(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
  }

  readonly contentTransform$: Observable<string | null> = this.isIOS()
    ? this.pulling$.pipe(
        map((distance) =>
          distance === PULLED_DISTANCE ? IOS_LOADING_DISTANCE : distance,
        ),
        map(translateY),
      )
    : of(null);

  readonly dropped$: Observable<boolean> = this.pulling$.pipe(
    map((distance) => distance <= MICRO_OFFSET || distance === PULLED_DISTANCE),
    distinctUntilChanged(),
  );
}
