import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'odin-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  @Input() public active = false;
  @Input() public overlay = false;
  @Input() public diameter = 60;
  @Input() public strokeWidth = 5;
  @Input() public colour = 'primary';
  @Input() public showContent = false;
}
