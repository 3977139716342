import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export class NavigationSettings {
  public pathMatch?: 'full' | 'partial' = 'partial';
  public opened?: boolean = false;
  public subRoutes?: NavigationItem[];
  public action?: () => void;
}

export class NavigationItem {
  public matchingRoute: string;
  constructor(
    public route: string,
    public safeRoute: string,
    public viewName: string,
    public icon: IconDefinition,
    public active: boolean,
    public enabled: boolean,
    public _matchingRoute?: string,
    public advanced?: NavigationSettings
  ) {
    if (_matchingRoute === undefined || _matchingRoute === null) this.matchingRoute = route;
    else this.matchingRoute = _matchingRoute;
  }

  public hasSubRoutes(): boolean {
    return this.advanced !== undefined && this.advanced.subRoutes !== undefined;
  }

  public hasVisiblesubRoutes(): boolean {
    if (!this.hasSubRoutes()) return true;
    if (this.advanced === undefined) return true;
    if (this.advanced.subRoutes === undefined) return true;

    return this.advanced?.subRoutes?.filter(sr => sr.enabled).length > 0;
  }

  public hasActiveSubRoute(): boolean {
    if (!this.hasSubRoutes()) return false;
    if (this.advanced === undefined) return false;
    if (this.advanced.subRoutes === undefined) return false;
    return this.advanced.subRoutes.filter(sr => sr.active).length > 0;
  }
}
