import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'odin-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
}
