<div class="p-1 max-width page-height overflow-auto">
  <mat-card>
    <mat-card-content>
      <ng-container *ngIf="page === 'payment'">
        <ng-container *ngTemplateOutlet="paymentForm"></ng-container>
      </ng-container>

      <ng-container *ngIf="page === 'payment-success'">
        <ng-container *ngTemplateOutlet="paymentSuccess"></ng-container>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #paymentForm>
  <odin-dynamic-form
    *ngIf="formsActive"
    [disabledInput]="!GrantChecker()"
    [formData]="peblForm"
    [submit]="formSubmit"
  ></odin-dynamic-form>

  <ng-container *ngIf="formStage > 0">
    <div class="mt-4">
      <p class="mb-2 text-center">Choose a method to send this payment link</p>
      <div class="d-flex align-items-center justify-content-center gap-4">
        <ng-container *ngFor="let sendMethodItem of sendMethods">
          <div
            style="max-width: 120px"
            (click)="SelectSendMethod(sendMethodItem.sendType)"
            [class.border-primary]="sendMethod === sendMethodItem.sendType"
            [class.text-primary]="sendMethod === sendMethodItem.sendType"
            class="p-2 rounded d-flex flex-column justify-content-center align-items-center border flex-fill"
          >
            <mat-icon>{{ sendMethodItem.icon }}</mat-icon>
            <p class="m-0">{{ sendMethodItem.viewName }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="formStage > 1">
    <div class="mt-4">
      <odin-dynamic-form
        *ngIf="formsActive"
        [disabledInput]="!GrantChecker() || isLink(sendMethod)"
        [refresh]="refreshFormState"
        [formData]="peblSendForm"
      ></odin-dynamic-form>
    </div>
  </ng-container>
</ng-template>

<ng-template #paymentSuccess>
  <h2>Link Status</h2>
  <div class="text-center">
    <div class="position-relative p-3 w-fit m-auto">
      <div
        class="d-flex align-items-center justify-content-center rounded-circle m-auto large-icon text-center"
      >
        <mat-icon style="color: green" class="d-block w-fit h-fit display-1"
          >verified</mat-icon
        >
      </div>
    </div>
    <p class="mb-0 fw-bold">Your payment link is active!</p>
    <p class="">Click below to copy to clipboard.</p>
    <div *ngIf="linkResponse">
      <div
        class="px-2 mt-2 mx-auto d-block d-md-flex justify-content-center align-items-start gap-2"
        style="max-width: 500px"
      >
        <mat-form-field
          class="dynamic form-field w-100"
          appearance="outline"
          (click)="copyRespText()"
        >
          <input matInput [(ngModel)]="linkResponse.token" [readonly]="true" />
        </mat-form-field>

        <button
          mat-flat-button
          style="height: 56px; width: fit-content"
          class="d-block"
          color="primary"
          (click)="copyRespText()"
        >
          <div class="d-flex align-items-center justify-content-between w-100">
            <mat-icon class="m-0 ms-1 d-inline">content_copy</mat-icon>
          </div>
        </button>
      </div>

      <qr-code
        *ngIf="qrEnabled"
        value="{{ linkResponse.token }}"
        errorCorrectionKLevel="M"
      ></qr-code>

      <p>{{ linkResponse.message }}</p>
    </div>

    <div class="mt-3">
      <button mat-raised-button color="accent" (click)="ResetPage()">
        Create New Link
      </button>
    </div>
  </div>
</ng-template>
